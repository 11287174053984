import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeBanner_style'
import { Box, Container, Hidden, Typography } from '@material-ui/core/'

import MultiFontText from 'shared/components/MultiFontText/MultiFontText'
import HomeSearch from 'shared/components/HomeSearch/HomeSearch'

const HomeBanner = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.banner}>
            <Container maxWidth='lg' disableGutters className={classes.bannerContainer}>
                <Box className={classes.bannerText}>
                    <MultiFontText>
                        <FormattedMessage id='landing_home_student.title'>
                            {text =>
                                <Typography className={classes.bannerTitle} variant='h1' dangerouslySetInnerHTML={{ __html: text }} />
                            }
                        </FormattedMessage>
                    </MultiFontText>
                    <Box className={classes.bannerCTA}>
                        <HomeSearch minimal/>
                    </Box>
                </Box>
                <Hidden mdDown>
                    <Box className={classes.bannerImage}>
                        <Image
                            cloudName='studentbe'
                            publicId='assets/boy_smiling'
                            alt='student.be employer boy smiling banner.'
                        >
                            <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                        </Image>
                    </Box>
                </Hidden>
            </Container>
        </Box>
    )
}
export default HomeBanner
