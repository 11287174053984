/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Image } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MenuNavigation_style'
import { useStyles as useDarkModeStyles } from './MenuNavigationDarkMode_style'
import { AppBar, Box, Container, Hidden } from '@material-ui/core'

import MobileAppBar from './MobileNavigation/MobileAppBar/MobileAppBar'
import NavigationButton from './NavigationButton/NavigationButton'
import NavigationUserActions from './NavigationUserActions/NavigationUserActions'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const MenuNavigation = (props) => {
    const { user, page, altLangUrls, paramsForUrlTranslation, selectedArticleTagObject, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    // Used to get the active Page.
    // Needed as we have the "Education" button in the navbar that should not be
    // active when the current page is "student-life" or other categories.
    const getStudentLifePage = () => {
        if (selectedArticleTagObject && selectedArticleTagObject.value === 'education') {
            return `student-life/categories/${selectedArticleTagObject.value}`
        } else {
            return page
        }
    }

    return <>
        <Hidden smDown>
            <AppBar color='white' elevation={0} className={classes.appBar}>
                <Container className={classes.container} disableGutters>
                    {/* Left container */}
                    <Box className={classes.leftContainer}>

                        <RouteMapLink page='/'>
                            <Hidden mdDown>
                                <Image
                                    width='130px'
                                    height='40px'
                                    cloudName='studentbe'
                                    publicId='student-be-logos/Student_2024_Logo_Main.png'
                                    alt='Student.be'
                                />
                            </Hidden>
                            <Hidden lgUp>
                                <Image
                                    width='48px'
                                    height='35px'
                                    cloudName='studentbe'
                                    publicId='student-be-logos/Student_2024_Logo_supporting.png'
                                    alt='Student.be'
                                />
                            </Hidden>
                        </RouteMapLink>

                        <Box className={classes.linksContainer}>
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.student_job_button' />}
                                page={page}
                                type='student-jobs'
                                darkMode={darkMode}
                            />

                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.internship_button' />}
                                page={page}
                                type='internships'
                                darkMode={darkMode}
                            />

                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.first_job_button' />}
                                page={page}
                                type='first-jobs'
                                darkMode={darkMode}
                            />

                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.companies_burger' />}
                                page={page}
                                type='companies'
                                darkMode={darkMode}
                            />

                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.education_new' />}
                                page={selectedArticleTagObject ? `student-life/categories/${selectedArticleTagObject.value}` : page}
                                type='student-life/categories/education'
                                darkMode={darkMode}
                            />

                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.kot_button' />}
                                page={page}
                                type='student-rooms'
                                darkMode={darkMode}
                            />

                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.news' />}
                                page={getStudentLifePage()}
                                type='student-life'
                                darkMode={darkMode}
                            />
                        </Box>
                    </Box>

                    {/* Right container */}
                    <Box className={classes.rightContainer}>
                        <NavigationUserActions
                            altLangUrls={altLangUrls}
                            user={user}
                            darkMode={darkMode}
                            paramsForUrlTranslation={paramsForUrlTranslation}
                        />
                    </Box>
                </Container>
            </AppBar>
        </Hidden>

        <MobileAppBar
            altLangUrls={altLangUrls}
            user={user}
            darkMode={darkMode}
            paramsForUrlTranslation={paramsForUrlTranslation}
        /></>
}

export default MenuNavigation
