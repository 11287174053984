import React from 'react'

import { useStyles } from './ExperienceProfile_style'
import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'icons/IconFactory/IconFactory'
import Button from 'components/Button/Button'
import Paper from 'components/Paper/Paper'
import ExperienceYearInput from './components/ExperienceYearInput/ExperienceYearInput'
import ExperienceSelectInput from './components/ExperienceSelectInput/ExperienceSelectInput'
import ExperienceCampusSelectInput from './components/ExperienceCampusSelectInput/ExperienceCampusSelectInput'
import ExperienceSchoolInput from './components/ExperienceSchoolInput/ExperienceSchoolInput'
import ExperienceStudyFieldInput from './components/ExperienceStudyFieldInput/ExperienceStudyFieldInput'
import ExperienceTextFieldInput from './components/ExperienceTextFieldInput/ExperienceTextFieldInput'
import ExperienceHighSchoolYearInput from './components/ExperienceHighSchoolYearInput/ExperienceHighSchoolYearInput'

import { FormattedMessage } from 'react-intl'

const ExperienceProfileViewDefault = (props) => {
    const {
        addNewFormation,
        disabledSave,
        disabledPaper,
        condensed,
        handleChangeStudyCycle,
        handleChangeFormation,
        handleChangeFormationDates,
        handleChangeHighSchoolStudyType,
        handleHideFormation,
        handleSchoolChange,
        highSchoolStudyTypeCollection,
        schoolCollection,
        setStudentFormation,
        studentFormation,
        studyFieldCollection
    } = props

    const classes = useStyles()

    const studyCycleCollection = CollectionHandler.Get('studyCycleCollection')
    const studyTypeCollection = CollectionHandler.Get('studyTypeCollection')

    return (
        <Paper className={classes.paper} hidden={disabledPaper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h2' component={'div'}>
                        {condensed
                            ? <FormattedMessage id='landing_experience_profile.studies_condensed' />
                            : <FormattedMessage id='landing_experience_profile.studies' />
                        }
                    </Typography>
                </Grid>
                {studentFormation.filter(experience => experience.deleted === false).map((formation, formationKey) => {
                    return (
                        <Grid key={formationKey.toString()} item xs={12} className={formation.deleted ? classes.displayNone : undefined}>
                            <Grid container >
                                <Box mb={2} component={Grid} item xs={12} className={classes.gridElem}>
                                    <Grid container spacing={1} mb={2} >
                                        <Grid container
                                            direction='column'
                                            justify='center'
                                            alignItems='flex-start'
                                            className={classes.gridFlex}
                                            item xs={12} md={3}
                                        >
                                            <FormattedMessage id='landing_experience_profile.type_of_cycle' />
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <ExperienceSelectInput
                                                type='study_cycle'
                                                collection={studyCycleCollection}
                                                formation={formation}
                                                formationKey={formationKey}
                                                handleChange={handleChangeStudyCycle(formationKey, 'study_cycle')}
                                                textFontClassName={classes.textFont}
                                                placeholderTextClassName={classes.placeholderTextSmallClassName}
                                                placeholderTextId='landing_experience_profile.choose'
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {formation.study_cycle === 'higher_education' && <>
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <Grid container spacing={1}>
                                            <Grid container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                                item xs={12} md={3}
                                            >
                                                <FormattedMessage id='landing_experience_profile.type_of_training' />
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <ExperienceSelectInput
                                                    type='study_type'
                                                    collection={studyTypeCollection}
                                                    formation={formation}
                                                    formationKey={formationKey}
                                                    handleChange={handleChangeFormation(formationKey, 'study_type')}
                                                    textFontClassName={classes.textFont}
                                                    placeholderTextClassName={classes.placeholderTextSmallClassName}
                                                    placeholderTextId='landing_experience_profile.choose_type_of_training'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={3}
                                                container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                            >
                                                <FormattedMessage id='landing_experience_profile.establishment' />
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <ExperienceSchoolInput
                                                    showEndAdornment
                                                    InputAdornmentClassName={classes.InputAdornment}
                                                    textFontClassName={classes.textFont}
                                                    type='study_type'
                                                    studentFormation={studentFormation}
                                                    handleSchoolChange={handleSchoolChange}
                                                    schoolCollection={schoolCollection}
                                                    formationKey={formationKey}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {formation.campuses &&
                                        <Grid item xs={12} className={classes.gridElem}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={3}
                                                    container
                                                    direction='column'
                                                    justify='center'
                                                    alignItems='flex-start'
                                                    className={classes.gridFlex}
                                                >
                                                    <FormattedMessage id='landing_experience_profile.campus' />
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <ExperienceCampusSelectInput
                                                        type='campus'
                                                        formation={formation}
                                                        formationKey={formationKey}
                                                        handleChange={handleChangeFormation}
                                                        textFontClassName={classes.textFont}
                                                        placeholderTextClassName={classes.placeholderTextSmallClassName}
                                                        placeholderTextId='landing_experience_profile.choose_campus'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {formation.school && formation.school.name === 'Other' &&
                                        <Grid item xs={12} className={classes.gridElem}>
                                            <Grid container spacing={1}>
                                                <Grid
                                                    item xs={12} md={3}
                                                    container
                                                    direction='column'
                                                    justify='center'
                                                    alignItems='flex-start'
                                                    className={classes.gridFlex}
                                                >
                                                    <FormattedMessage id='landing_experience_profile.other_establishment' />
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <ExperienceTextFieldInput
                                                        value={formation.other_school}
                                                        handleChange={handleChangeFormation(formationKey, 'other_school')}
                                                        placeholderTextId='landing_experience_profile.indicate_establishment'
                                                        textFontClassName={classes.textFont}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Box component={Grid} item xs={12} className={classes.gridElem}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item xs={12} md={3}
                                                container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                            >
                                                <FormattedMessage id='landing_experience_profile.field_of_study' />
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <ExperienceStudyFieldInput
                                                    showEndAdornment
                                                    InputAdornmentClassName={classes.InputAdornment}
                                                    textFontClassName={classes.textFont}
                                                    type='study_type'
                                                    studentFormation={studentFormation}
                                                    setStudentFormation={setStudentFormation}
                                                    studyFieldCollection={studyFieldCollection}
                                                    formationKey={formationKey}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {formation.specializationList &&
                                        <Grid item xs={12} className={classes.gridElem}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={3}
                                                    container
                                                    direction='column'
                                                    justify='center'
                                                    alignItems='flex-start'
                                                    className={classes.gridFlex}
                                                >
                                                    <FormattedMessage id='landing_experience_profile.specialization' />
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <ExperienceSelectInput
                                                        type='specialization'
                                                        collection={formation.specializationList}
                                                        formation={formation}
                                                        formationKey={formationKey}
                                                        handleChange={handleChangeFormation(formationKey, 'specialization')}
                                                        textFontClassName={classes.textFont}
                                                        placeholderTextClassName={classes.placeholderTextSmallClassName}
                                                        placeholderTextId='landing_experience_profile.choose_specialization'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {formation.field_of_study && formation.field_of_study.name === 'Other' &&
                                        <Grid item xs={12} className={classes.gridElem}>
                                            <Grid container spacing={1}>
                                                <Grid
                                                    item xs={12} md={3}
                                                    container
                                                    direction='column'
                                                    justify='center'
                                                    alignItems='flex-start'
                                                    className={classes.gridFlex}
                                                >
                                                    <FormattedMessage id='landing_experience_profile.other_field_of_study' />
                                                </Grid>
                                                <Grid item xs={12} md={9} >
                                                    <ExperienceTextFieldInput
                                                        value={formation.other_field_of_study}
                                                        handleChange={handleChangeFormation(formationKey, 'other_field_of_study')}
                                                        placeholderTextId='landing_experience_profile.indicate_establishment'
                                                        textFontClassName={classes.textFont}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {formation.study_type &&
                                        <Grid item xs={12} className={classes.gridElem}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={8}
                                                    container
                                                    direction='column'
                                                    justify='center'
                                                    alignItems='flex-start'
                                                    className={classes.gridFlex}
                                                >
                                                    <FormattedMessage id='landing_experience_profile.start_date' />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid container justify='space-around'>
                                                        <ExperienceYearInput
                                                            className={classes.date}
                                                            value={formation.start_year}
                                                            onChange={handleChangeFormationDates(formationKey, 'start_year')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {formation.showEndYear &&
                                        <Grid item xs={12} className={classes.gridElem}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={8}
                                                    container
                                                    direction='column'
                                                    justify='center'
                                                    alignItems='flex-start'
                                                    className={classes.gridFlex}
                                                >
                                                    <FormattedMessage
                                                        values={{ study_type: CollectionHandler.Translate('studyTypeCollection', formation.study_type) }}
                                                        id='landing_experience_profile.end_date'
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid container justify='space-around'>
                                                        <ExperienceYearInput
                                                            className={classes.date}
                                                            value={formation.end_year}
                                                            onChange={handleChangeFormationDates(formationKey, 'end_year')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item xs={12}/>
                                </>}
                                {formation.study_cycle === 'high_school' && <>
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <Grid container spacing={1}>
                                            <Grid container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                                item xs={12} md={3}
                                            >
                                                <FormattedMessage id='landing_experience_profile.type_of_training' />
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <ExperienceSelectInput
                                                    type='study_type'
                                                    collection={highSchoolStudyTypeCollection}
                                                    formation={formation}
                                                    formationKey={formationKey}
                                                    handleChange={handleChangeHighSchoolStudyType(formationKey, 'study_type')}
                                                    textFontClassName={classes.textFont}
                                                    placeholderTextClassName={classes.placeholderText}
                                                    placeholderTextId='landing_experience_profile.choose_type_of_training'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {formation.highSchoolStudyFields && formation.highSchoolStudyFields.length > 0 &&
                                            <Grid item xs={12} className={classes.gridElem}>
                                                <Grid container spacing={1}>
                                                    <Grid container
                                                        direction='column'
                                                        justify='center'
                                                        alignItems='flex-start'
                                                        className={classes.gridFlex}
                                                        item xs={12} md={3}
                                                    >
                                                        <FormattedMessage id='landing_experience_profile.specialization' />
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        <ExperienceSelectInput
                                                            type='other_field_of_study'
                                                            collection={formation.highSchoolStudyFields}
                                                            formation={formation}
                                                            formationKey={formationKey}
                                                            handleChange={handleChangeFormation(formationKey, 'other_field_of_study')}
                                                            textFontClassName={classes.textFont}
                                                            placeholderTextClassName={classes.placeholderText}
                                                            placeholderTextId='landing_experience_profile.choose_specialization'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    }
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item xs={12} md={3}
                                                container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                            >
                                                <FormattedMessage id='landing_experience_profile.current_establishment' />
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <ExperienceTextFieldInput
                                                    value={formation.other_school}
                                                    handleChange={handleChangeFormation(formationKey, 'other_school')}
                                                    placeholderTextId='landing_experience_profile.indicate_establishment'
                                                    textFontClassName={classes.textFont}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} md={6}
                                                container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                            >
                                                <FormattedMessage id='landing_experience_profile.current_school_year' />
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <ExperienceHighSchoolYearInput
                                                    value={formation.high_school_year}
                                                    formationKey={formationKey}
                                                    handleChangeFormation={handleChangeFormation}
                                                    textFontClassName={classes.textFont}
                                                    placeholderTextId='landing_experience_profile.choose_school_year'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                            {!condensed &&
                                <Box className={classes.buttonsContainer} display='flex' justifyContent='flex-end'>
                                    {!disabledSave &&
                                        <Box display='flex' justify='flex-end' alignItems='center'>
                                            <Button
                                                type='submit'
                                                size='small'
                                                variant='primary'
                                            >
                                                <FormattedMessage id='landing_experience_profile.save' />
                                            </Button>
                                        </Box>
                                    }
                                    <Box ml={2}>
                                        <IconButton
                                            className={classes.buttonOutlined}
                                            onClick={() => handleHideFormation(formation)}
                                        >
                                            <IconFactory color='black' icon='delete'/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            }
                        </Grid>
                    )
                })}
                <Grid item xs={12}>
                    {!condensed &&
                        <Button
                            size='small'
                            variant='primary'
                            onClick={addNewFormation}
                            endIcon={<IconFactory icon='add'/>}
                        >
                            <FormattedMessage id='landing_experience_profile.add_training' />
                        </Button>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ExperienceProfileViewDefault
