import AboutFAQ from '../bundles/pages/AboutFAQ/AboutFAQ'
import AboutFirstJobs from '../bundles/pages/AboutFirstJobs/AboutFirstJobs'
import AboutInternships from '../bundles/pages/AboutInternships/AboutInternships'
import AboutKotOwners from '../bundles/pages/AboutKotOwners/AboutKotOwners'
import AboutKots from '../bundles/pages/AboutKots/AboutKots'
import AboutPress from '../bundles/pages/AboutPress/AboutPress'
import AboutPublishStudentJob from '../bundles/pages/AboutPublishStudentJob/AboutPublishStudentJob'
import AboutStudentJobs from '../bundles/pages/AboutStudentJobs/AboutStudentJobs'
import AboutSummerJobs from '../bundles/pages/AboutSummerJobs/AboutSummerJobs'
import AboutTerms from '../bundles/pages/AboutTerms/AboutTerms'
import AboutUs from '../bundles/pages/AboutUs/AboutUs'
import CompanyArticles from '../bundles/pages/user/Articles/CompanyArticles'
import CompanyProfile from '../bundles/pages/user/CompanyProfile/CompanyProfileMain/CompanyProfile'
import CompanyProfileFormPage from '../bundles/pages/user/CompanyProfileForm/CompanyProfileFormPage'
import Congratulation from '../bundles/pages/user/Congratulation/Congratulation'
import ContactMain from '../bundles/pages/Contact/ContactMain'
import ContactEmployerPage from '../bundles/pages/Contact/ContactEmployerPage'
import EmployerJobIndex from '../bundles/pages/user/EmployerJobIndex/EmployerJobIndex'
import ForgotPassword from '../bundles/pages/user/ForgotPassword/ForgotPassword'
import HeadHunt from '../bundles/pages/user/Headhunt/HeadHunt/HeadHunt'
import HeadhuntResponse from '../bundles/pages/user/Headhunt/HeadhuntResponse/HeadhuntResponse'
import HomeEmployer from '../bundles/pages/HomeEmployer/HomeEmployer'
import HomeMyBS from '../bundles/pages/HomeMyBS/HomeMyBS'
import HomeStudentMain from '../bundles/pages/HomeStudent/HomeStudentMain'
import IndexRouter from '../bundles/router/IndexRouter'
import FirstJobFunnel from '../bundles/pages/user/JobCreationFunnel/FirstJobFunnel/FirstJobFunnel'
import InternshipFunnel from '../bundles/pages/user/JobCreationFunnel/InternshipFunnel/InternshipFunnel'
import StudentJobFunnel from '../bundles/pages/user/JobCreationFunnel/StudentJobFunnel/StudentJobFunnel'
import JobResults from '../bundles/pages/user/JobResults/JobResults'
import JobSearchStatusResponse from '../bundles/pages/user/Profile/StudentProfile/Components/JobSearchStatusResponse/JobSearchStatusResponse'
import LoginRegister from '../bundles/pages/LoginRegister/LoginRegister/LoginRegister'
import Lost from '../bundles/pages/Lost/Lost'
import MessageMainEmployer from '../bundles/pages/user/Messages/MessagesMain/MessageMainEmployer'
import MessageMainKotOwner from '../bundles/pages/user/Messages/MessagesMain/MessageMainKotOwner'
import MessageMainStudent from '../bundles/pages/user/Messages/MessagesMain/MessageMainStudent'
import MultiStepRegister from '../bundles/pages/LoginRegister/Register/MultiStepRegister/MultiStepRegister'
import MyItemSelectionIndexMain from '../bundles/pages/user/MyItemSelection/MyItemSelectionIndex/MyItemSelectionIndexMain'
import MyKotIndex from '../bundles/pages/user/MyKotIndex/MyKotIndex'
import NewAdMain from '../bundles/pages/user/JobCreationFunnel/NewJob/NewAdMain'
import NewJobForm from '../bundles/pages/user/JobCreationFunnel/NewJob/NewJobForm'
import NewArticle from '../bundles/pages/user/NewArticle/NewArticle'
import NewKotMain from '../bundles/pages/user/Kots/NewKot/NewKotMain'
import NewPassword from '../bundles/pages/user/NewPassword/NewPassword'
import PaymentPage from '../bundles/pages/AboutPayment/PaymentPage'
import PersonalDashboard from '../bundles/pages/user/PersonalDashboard/PersonalDashboard'
import AdminDataPage from '../bundles/pages/AdminDataPage/AdminDataPage'
import Profile from '../bundles/pages/user/Profile/Profile'
import PromotionRouter from '../bundles/router/pages/PromotionRouter'
import ReactOnRails from 'react-on-rails'
import Settings from '../bundles/pages/user/Settings/Settings'
import Welcome from '../bundles/pages/user/Welcome/Welcome'

// Landing Pages
import JobfairsLandingPage from '../bundles/pages/LandingPages/JobfairsLandingPage'
import PodcastPage from '../bundles/pages/LandingPages/PodcastPage'
import RecruitmentSelectionPage from '../bundles/pages/LandingPages/RecruitmentSelectionPage'
import StudentJobLandingPage from '../bundles/pages/LandingPages/StudentJobLandingPage'

// Admin components
import AdminDocs from '../bundles/Admin/AdminDocs'
import AdminEditAd from '../bundles/Admin/Ads/AdminEditAd'
import AdminEditArticle from '../bundles/Admin/Articles/AdminEditArticle'
import AdminEditCompany from '../bundles/Admin/Companies/AdminEditCompany'
import AdminEditVideo from '../bundles/Admin/Videos/AdminEditVideo'
import AdminDashboard from '../bundles/Admin/AdminDashboard'
import AdminExports from '../bundles/Admin/AdminExports'
import AdminHealthMetrics from '../bundles/Admin/AdminHealthMetrics'

// This is how react_on_rails can see the HelloWorld in the browser.
// Only put components in this file that are called in the view, otherwise, they are useless.

ReactOnRails.register({
    AboutFAQ,
    AboutFirstJobs,
    AboutInternships,
    AboutKotOwners,
    AboutKots,
    AboutPress,
    AboutPublishStudentJob,
    AboutStudentJobs,
    AboutSummerJobs,
    AboutTerms,
    AboutUs,
    AdminEditVideo,
    CompanyArticles,
    CompanyProfile,
    CompanyProfileFormPage,
    Congratulation,
    ContactMain,
    ContactEmployerPage,
    EmployerJobIndex,
    FirstJobFunnel,
    ForgotPassword,
    HeadHunt,
    HeadhuntResponse,
    HomeEmployer,
    HomeMyBS,
    HomeStudentMain,
    IndexRouter,
    InternshipFunnel,
    JobResults,
    JobSearchStatusResponse,
    JobfairsLandingPage,
    LoginRegister,
    Lost,
    MessageMainEmployer,
    MessageMainKotOwner,
    MessageMainStudent,
    MultiStepRegister,
    MyItemSelectionIndexMain,
    MyKotIndex,
    NewAdMain,
    NewArticle,
    NewJobForm,
    NewKotMain,
    NewPassword,
    PaymentPage,
    PersonalDashboard,
    AdminDataPage,
    PodcastPage,
    Profile,
    PromotionRouter,
    RecruitmentSelectionPage,
    Settings,
    StudentJobFunnel,
    StudentJobLandingPage,
    Welcome,
    AdminDashboard,
    AdminDocs,
    AdminEditAd,
    AdminEditArticle,
    AdminEditCompany,
    AdminExports,
    AdminHealthMetrics
})
