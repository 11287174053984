import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    routeMapLink: {
        width: '100%',
        maxWidth: '341px',
        height: '308px',
        [theme.breakpoints.up('sm')]: {
            width: '302px',
            maxWidth: 'unset'
        },
    },

    NewsCardContainer: {
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.fg['silver']}`,
        borderRadius: '20px',
        backgroundColor: theme.palette.bg['white'],
        display: 'flex',
        flexDirection: 'column'
    },

    imageContainer: {
        width: '100%',
        height: '141px',
        [theme.breakpoints.up('sm')]: {
            height: '173px'
        }
    },

    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },

    newsInfoContainer: {
        width: '100%',
        flex: 1,
        padding: '18px 16px 23px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '17px',
            paddingBottom: '17px'
        },
    },

    upContainer: {

    },

    categoryTagContainer: {
        paddingBottom: '20px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '8px'
        },
    },

    chip: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg['silver'],
        borderRadius: '50px',
        padding: '2px 10px',
        fontFamily: 'GreedStandard-Medium',
        fontSize: '9px',
        lineHeight: '16px',
        textTransform: 'capitalize'
    },

    chipLabel: {
        padding: 0
    },

    title: {
        color: theme.palette.fg['black'],
        fontSize: '21px',
        lineHeight: '24px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
            lineHeight: '23px'
        },
    },

    downContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '14px'
    },

    downText: {
        color: theme.palette.fg['black40Opacity'],
        fontSize: '12px',
        lineHeight: '15px'
    },

    dateContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px'
    },

    readTimeContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px'
    }
}))
