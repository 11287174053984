import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeCompanyFilter_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import NewCarouselCards from 'shared/components/NewCarouselCards/NewCarouselCards'
import CollectionHandler from 'assets/CollectionHandler.js'

const HomeCompanyFilter = (props) => {
    const filter = CollectionHandler.Get('homeCarouselCardCollection')
    const classes = useStyles()

    return (
        <Box className={classes.companyFilterContainer}>
            <Box className={classes.companyFilterText}>
                <Typography
                    component='h2'
                    color='primary'
                    className={classes.companyFilterTitle}
                >
                    <FormattedMessage id="landing_student.companyFilter_title" />
                </Typography>
                <Typography
                    component='p'
                    color='primary'
                    className={classes.companyFilterDescription}
                >
                    <FormattedMessage id="landing_student.companyFilter_description" />
                </Typography>
            </Box>
            <Box className={classes.companyFilterCarousel}>
                <NewCarouselCards
                    type='companyFilter'
                    items={filter}
                    autoPlay={true}
                    cardsNumber={3}
                    allowButtons={true}
                />
            </Box>
        </Box>
    )
}
export default HomeCompanyFilter
