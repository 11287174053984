import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    formTitle: {
        position: 'absolute',
        top: 60,
        color: 'var(--foreground-black)',
        fontFamily: 'GreedWide-Bold',
        fontSize: 32,
        lineHeight: 1.2,
    },
    jobSelectionForm: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'flex-start',
            height: '100%',
            padding: 12,
        }
    },
    jobType: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        height: 200,
        width: 220,
        border: '1px solid var(--background-silver)',
        borderRadius: 20,
        cursor: 'pointer',
        '&:hover': {
            borderColor: 'var(--foreground-black)',
            boxShadow: `4px 4px 0px 0px var(--foreground-black)`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'fit-content',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: 14
        }
    },
    selectedJobType: {
        borderColor: 'var(--foreground-black)',
        boxShadow: `4px 4px 0px 0px var(--foreground-black)`,
    },
    iconContainer: {
        width: 80,
        height: 80,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& >svg': {
            height: 40,
            width: 40
        }
    },
    jobIcon: {
        fontSize: 12,
        height: 24,
        width: 24,
        padding: 14,
        background: 'var(--beige)',
        borderRadius: 12,
    },
    jobTitle: {
        textTransform: 'capitalize',
        fontSize: 16,
    },
    button: {
        position: 'absolute',
        bottom: 80,
        minWidth: 240
    }
}))
