import React, { useState, useEffect } from 'react'
import RouteMap from 'assets/RouteMap'
import CollectionHandler from 'assets/CollectionHandler.js'
import ExperienceProfileView from './ExperienceProfileView'
import ExperienceHelper from 'assets/ExperienceHelper'

const ExperienceProfile = (props) => {
    const {
        disabledSave,
        disabledPaper,
        condensed,
        setStudentFormation,
        studentFormation,
        setExperienceIsUpdated,
        black, inputRefs, errors,
        highlightInputField
    } = props

    const highSchoolStudyTypeCollection = CollectionHandler.Get('highSchoolStudyTypeCollection')
    const schoolCollection = CollectionHandler.Get('schoolCollection')
    const studyFieldCollectionExcludeOutdated = CollectionHandler.Get('studyFieldCollectionExcludeOutdated')
    const studyTypeCollection = CollectionHandler.Get('studyTypeCollection')

    useEffect(() => {
        ExperienceHelper.AddOtherToCollection(studyFieldCollectionExcludeOutdated)
        ExperienceHelper.AddOtherToCollection(schoolCollection)
    }, [])

    const addNewFormation = () => {
        setStudentFormation([...studentFormation, { ...ExperienceHelper.BLANK_FORMATION }])
    }

    const handleChangeFormation = (index, prop) => (event) => {
        setExperienceIsUpdated(true)
        studentFormation[index][prop] = event.target.value
        setStudentFormation([...studentFormation])
    }
    const handleChangeStudyCycle = (index, prop) => (event) => {
        setExperienceIsUpdated(true)
        studentFormation[index].field_of_study = null
        studentFormation[index].field_of_study_id = ''
        studentFormation[index].other_field_of_study = ''
        studentFormation[index].specialization = ''
        studentFormation[index].campus = ''
        studentFormation[index].start_year = null
        studentFormation[index].end_year = null
        studentFormation[index].showEndYear = false
        studentFormation[index].deleted = false
        studentFormation[index].other_school = ''
        studentFormation[index].study_type = ''
        studentFormation[index].otherStudy = ''
        studentFormation[index].school = null
        studentFormation[index].campuses = ''
        studentFormation[index].specializationList = ''
        studentFormation[index].additionalInfoError = false
        studentFormation[index].additionalInfoErrorMessage = false
        studentFormation[index].study_cycle = ''
        studentFormation[index].high_school_year = null
        studentFormation[index].highSchoolStudyFields = []
        studentFormation[index][prop] = event.target.value
        setStudentFormation([...studentFormation])
    }
    const handleChangeHighSchoolStudyType = (index, prop) => (event) => {
        setExperienceIsUpdated(true)
        studentFormation[index][prop] = event.target.value
        setStudentFormation([...studentFormation])

        const table = highSchoolStudyTypeCollection.find((element) => element.value === event.target.value)
        studentFormation[index].highSchoolStudyFields = table.field_of_studies
    }

    const handleChangeFormationDates = (index, prop) => (event) => {
        setExperienceIsUpdated(true)
        const inputYear = event && event.getTime()
        if (prop === 'start_year') {
            let studyLength = studyTypeCollection.find(type => type.value === studentFormation[index].study_type).study_length
            if (studentFormation[index].field_of_study?.value === 'nursing') {
                studyLength++
            }
            if (studentFormation[index].field_of_study?.value === 'midwifery') {
                if (RouteMap.GetLocaleFromUrl() === 'fr' || studentFormation[index].school.language === 'fr') {
                    studyLength++
                }
            }
            if (inputYear && !ExperienceHelper.IsValidDate(studentFormation[index].end_year)) {
                studentFormation[index].end_year = new Date(event.setFullYear(event.getFullYear() + studyLength))
                studentFormation[index].showEndYear = true
            }
        }
        if (inputYear) {
            studentFormation[index][prop] = new Date(inputYear)
        } else {
            studentFormation[index][prop] = event
        }
        setStudentFormation([...studentFormation])
    }

    const handleHideFormation = (formation) => {
        setExperienceIsUpdated(true)
        if (formation.id) {
            const formationToDelete = studentFormation.find(form => form.id === formation.id)
            if (formationToDelete) {
                formationToDelete.deleted = true
            }
            setStudentFormation([...studentFormation])
        } else {
            const a = studentFormation.filter(form => {
                return form !== formation
            })
            setStudentFormation([...a])
        }
    }

    const handleSchoolChange = (newValue, formationKey) => {
        setExperienceIsUpdated(true)
        if (newValue.name !== 'Other') {
            studentFormation[formationKey].other_school = ''
        }
        schoolCollection.forEach(
            (fields) => {
                if (fields.name === newValue.name) {
                    studentFormation[formationKey].school = fields
                    if (fields.campuses) {
                        studentFormation[formationKey].campuses = fields.campuses.sort((a, b) => a.location.localeCompare(b.location))
                    } else {
                        studentFormation[formationKey].campuses = ''
                    }
                    setStudentFormation([...studentFormation])
                }
            }
        )
    }

    return (
        <ExperienceProfileView
            black={black}
            disabledPaper={disabledPaper}
            addNewFormation={addNewFormation}
            disabledSave={disabledSave}
            condensed={condensed}
            handleChangeStudyCycle={handleChangeStudyCycle}
            handleChangeFormation={handleChangeFormation}
            handleChangeFormationDates={handleChangeFormationDates}
            handleChangeHighSchoolStudyType={handleChangeHighSchoolStudyType}
            handleHideFormation={handleHideFormation}
            highSchoolStudyTypeCollection={highSchoolStudyTypeCollection}
            schoolCollection={schoolCollection}
            setStudentFormation={setStudentFormation}
            handleSchoolChange={handleSchoolChange}
            studentFormation={studentFormation}
            studyFieldCollection={studyFieldCollectionExcludeOutdated}
            inputRefs={inputRefs}
            errors={errors}
            highlightInputField={highlightInputField}
        />
    )
}
export default ExperienceProfile
