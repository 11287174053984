import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    tableOfContents: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30
    },
    tocTitle: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontWeight: 700,
        fontSize: 18,
        color: 'var(--foreground-black)',
        opacity: 0.4,
        cursor: 'not-allowed',
        transition: 'all 0.2s ease-in',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    validStep: {
        cursor: 'pointer',
        opacity: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    selectedTocTitle: {
        cursor: 'pointer',
        fontSize: 22,
        opacity: 1,
        '&:hover': {
            marginLeft: '0px !important',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'inherit'
        }
    },
    navigationBlocked: {
        cursor: 'not-allowed',
    },
    navigationAllowed: {
        '&:hover': {
            marginLeft: 8,
        },
    }
}))
