import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './NewsAndTipsView_style'
import { Container, Box, Typography, Hidden } from '@material-ui/core'
import Button from 'components/Button/Button.jsx'
import NewsCard from 'components/NewsCard/NewsCard.jsx'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NewsAndTipsView = (props) => {
    const { articles, moreItemsURL, titleId, subTitleId } = props
    const classes = useStyles()

    return (
        <Container className={classes.NewsAndTipsContainer} maxWidth='lg' disableGutters>
            <Typography
                className={classes.title}
                component='h1'
            >
                <FormattedMessage id={titleId} />
            </Typography>
            <Hidden xsDown>
                <Typography
                    className={classes.description}
                    component='p'
                >
                    <FormattedMessage id={subTitleId} />
                </Typography>
            </Hidden>
            <Box className={classes.cardsContainer}>
                {articles.map(article => <NewsCard key={article.id} article={article} />)}
            </Box>
            <Box className={classes.buttonContainer}>
                <RouteMapLink redirectionUrl={moreItemsURL}>
                    <Button variant='cta-primary'>
                        <FormattedMessage id='job_show.news_and_tips.more_link' /></Button>
                </RouteMapLink>
            </Box>
        </Container>
    )
}

export default NewsAndTipsView
