import React, { useState, useEffect } from 'react'
import NewsAndTipsView from 'components/NewsAndTipsView/NewsAndTipsView.jsx'
import ArticleAPI from 'api/ArticleAPI'
import RouteMap from 'assets/RouteMap.js'

const NewsAndTips = (props) => {
    const { job } = props
    const [articles, setArticles] = useState([])

    useEffect(() => {
        getSimilarArticles()
    }, [])

    const getSimilarArticles = () => {
        const data = {
            category: job.type,
            count: 3
        }
        ArticleAPI.GetRecentArticles(data).then((response) => {
            setArticles(response.data?.slice(0, 4))
        })
    }

    if (articles.length === 0) return null

    return (
        <NewsAndTipsView
            articles={articles}
            moreItemsURL={RouteMap.ArticleCategory(job.type)}
            titleId='job_show.news_and_tips.title'
            subTitleId='job_show.news_and_tips.description'
        />
    )
}

export default NewsAndTips
