import React from 'react'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './NewsCard_style'
import { Box, Chip, Typography, useTheme } from '@material-ui/core'

import { SMALL_SIZE } from 'assets/CloudinaryURLHelper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler.js'
import DateHandler from 'assets/DateHandler'

const NewsCard = (props) => {
    const classes = useStyles()
    const { article } = props
    const theme = useTheme()
    const coverImage = article.cover_image.cloudinary_key || 'default-company-logo-black'

    return (
        <RouteMapLink
            showItemType='article'
            showItemUrl={article.url}
            className={classes.routeMapLink}
        >
            <Box className={classes.NewsCardContainer}>
                <Box className={classes.imageContainer}>
                    <Image
                        cloudName="studentbe"
                        className={classes.image}
                        publicId={coverImage}
                        alt='sector image'
                    >
                        <Transformation flags="progressive" fetchFormat="auto" height={SMALL_SIZE} quality="auto:good" crop="fill" />
                        <Transformation defaultImage="placeholders:other_industries.png" />
                    </Image>
                </Box>
                <Box className={classes.newsInfoContainer}>
                    <Box className={classes.upContainer}>
                        <Box className={classes.categoryTagContainer}>
                            <Chip
                                classes={{ label: classes.chipLabel }}
                                className={classes.chip}
                                label={CollectionHandler.Translate('articleCategoryCollection', article.category)}
                                key={article.category}
                                name={article.category}
                            />
                        </Box>
                        <Typography
                            variant='h2'
                            component='p'
                            className={classes.title}
                        >
                            {article.title}
                        </Typography>
                    </Box>
                    <Box className={classes.downContainer}>
                        <Box className={classes.readTimeContainer}>
                            <IconFactory icon='calendar' fontSize={14} color={theme.palette.fg['black40Opacity']} />
                            <Typography
                                component='span'
                                className={classes.downText}
                            >
                                {DateHandler.toReadingFormat(article.date)}
                            </Typography>
                        </Box>
                        <Box className={classes.readTimeContainer}>
                            <IconFactory icon='clock' fontSize={14} color={theme.palette.fg['black40Opacity']} />
                            <Typography
                                component='span'
                                className={classes.downText}
                            >
                                {article.read_time} mins
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </RouteMapLink>)

}

export default NewsCard
