import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative !important',
        width: '100%',
        backgroundColor: theme.palette.bg.purple
    },

    container: {
        width: '100%',
        maxWidth: '1440px',
        padding: '16px',
        paddingRight: '6px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '7px',
        [theme.breakpoints.up(1228)]: {
            paddingRight: '16px',
        },
    },

    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '7px',
        [theme.breakpoints.up(1000)]: {
            columnGap: '15px',
        },
        [theme.breakpoints.up(1150)]: {
            columnGap: '25px',
        }
    },

    linksContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '8px',
        [theme.breakpoints.up(1030)]: {
            columnGap: '14px',
        },
        [theme.breakpoints.up(1200)]: {
            columnGap: '28px',
        }
    },

    rightContainer: {

    }
}))
