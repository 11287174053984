import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from '../../ExperienceProfile_style'

const ExperienceYearInput = (props) => {
    const { className, inputRef, error, onChange, value, disableUnderline } = props
    const classes = useStyles()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                error={false}
                keyboardIcon={<IconFactory icon='date' color='black' />}
                className={`${className} ${error && classes.textFieldError}`}
                required
                views={['year']}
                openTo='year'
                format='yyyy'
                value={value}
                onChange={onChange}
                KeyboardButtonProps={{ 'aria-label': 'change year' }}
                InputProps={{ disableUnderline: disableUnderline }}
                inputRef={inputRef}
                invalidDateMessage=''
                maxDateMessage=''
                minDateMessage=''
            />
        </MuiPickersUtilsProvider>
    )
}

export default ExperienceYearInput
