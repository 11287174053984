import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './CTABannerHiring_style'
import { Typography, Container, Box, Hidden } from '@material-ui/core'

import CTABannerButton from 'shared/components/CTABannerButton/CTABannerButton.jsx'

const CTABannerHiring = (props) => {
    const { userType, type } = props
    const classes = useStyles()

    return (
        <Container className={classes.bannerContainer} disableGutters>
            <Box className={classes.upContainer}>
                <FormattedMessage id='banner_hiring.title'>
                    {text =>
                        <Typography className={classes.titleContainer} variant='h1' component='p' dangerouslySetInnerHTML={{ __html: text }} />
                    }
                </FormattedMessage>
                <Typography className={classes.description}>  <FormattedMessage id='banner_hiring.description' /></Typography>
                <CTABannerButton userType={userType} type={type} textId='banner_hiring.button.label' />
            </Box>

            <Box className={classes.downContainer}>
                <Hidden smUp>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/girl_smiling_inverse'
                        alt='student.be post 3 jobs for free'
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                    </Image>
                </Hidden>
                <Hidden xsDown>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/girl_smiling'
                        alt='student.be post 3 jobs for free'
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                    </Image>
                </Hidden>
            </Box>
        </Container >

    )
}

export default CTABannerHiring
