import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    userActionsLogginContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '12px',
        [theme.breakpoints.up(1000)]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.up(1300)]: {
            columnGap: '27px',
        }
    },

    iconsContainer: {
        width: 'fit-content',
        maxWidth: '114px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: '19px',
        rowGap: '12px',
        flexWrap: 'wrap',
        [theme.breakpoints.up(1000)]: {
            maxWidth: 'unset'
        }
    }
}))
