import React from 'react'
import RouteMap from 'assets/RouteMap'
import DateHandler from 'assets/DateHandler'
import ShareAction from 'components/ShareAction/ShareAction'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { Tooltip, Typography, IconButton, Box, Hidden } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './ArticleShowCard_style'

const ArticleShowCard = (props) => {
    const { title, companyUrl, companyName, date, readingTime, isOwner, isAdmin, id } = props
    const classes = useStyles()

    return (
        <Box className={classes.mainBox}>
            <Box className={classes.homeLine}>
                <Typography className={classes.homeLineBlogHome} onClick={() => window.history.back()}>
                    <FormattedMessage id='landing_article_show.blog_home'/>
                </Typography>
                {'>'}
                <Typography className={classes.homeLineTitle}>
                    {title}
                </Typography>
            </Box>
            <Typography variant='h1' className={classes.title}>
                {title}
            </Typography>
            <Box className={classes.container}>
                <Box className={classes.infoContainer}>
                    <Typography variant='subtitle1'>
                        <FormattedMessage id='landing_article_show.by'/>
                        <RouteMapLink redirectionUrl={companyUrl}>
                            <span>{companyName}</span>
                        </RouteMapLink>
                    </Typography>
                    <time dateTime={DateHandler.ToSchemaFormat(date)}>
                        <Typography variant='subtitle1'>
                            {DateHandler.ToBasicFormat(date)}
                        </Typography>
                    </time>
                    <Hidden xsDown>
                        <Typography className={classes.readingTime} variant='subtitle1'>
                            <IconFactory icon='clock' fontSize={14} />
                            {readingTime} mins
                        </Typography>
                    </Hidden>
                    <Hidden smDown>
                        <Typography className={classes.share} variant='subtitle1'>
                            <FormattedMessage id='landing_article_show.share_this_article'/>
                            <ShareAction
                                infos={{ title: title, url: window.location.href, type: 'article' }}
                                tooltip='Partager'
                                size='14px'
                            />
                        </Typography>
                    </Hidden>
                </Box>
                <Box>
                    {(isOwner || isAdmin) && (
                        <Tooltip title={'Editer votre annonce'}>
                            <IconButton onClick={() => {
                                window.location.href = RouteMap.Edit('article', id)
                            }}>
                                <IconFactory icon='edit' className={classes.editIcon}/>
                            </IconButton>
                        </Tooltip>
                    )}
                    <ShareAction
                        infos={{ title: title, url: window.location.href, type: 'article' }}
                        tooltip='Partager'
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default ArticleShowCard
