import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    banner: {
        display: 'flex',
        width: '100%',
        height: 500,
        backgroundColor: theme.palette.bg.purple,
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.down('md')]: {
            height: 400
        },
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content'
        }
    },
    bannerContainer: {
        display: 'flex',
        maxWidth: 1440
    },
    bannerText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 24,
        width: '50%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: 70,
            width: '100%',
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '40px 60px'
        }
    },
    bannerTitle: {
        color: theme.palette.fg.white,
        fontSize: 72,
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 45
        }
    },
    bannerImage: {
        display: 'flex',
        width: '50%',
        justifyContent: 'center',
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            width: 0
        },
        '& >img': {
            height: 500
        }
    }
}))
