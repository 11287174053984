import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography, MenuItem, Divider } from '@material-ui/core'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { useStyles } from './NotificationBox_style'

const NotificationBox = (props) => {
    const { user } = props
    const classes = useStyles()

    return (<>
        {user.unseen_messages > 0 && <>
            <RouteMapLink page='messages'>
                <MenuItem className={classes.itemBox}>
                    <Box className={classes.textBox}>
                        <Typography variant='h6' className={classes.title}>
                            <FormattedMessage id={`personal_dashboard.notification_box.messages.title.${user.unseen_messages > 0 ? 'present' : 'none'}`} />
                        </Typography>
                        <Typography variant='body1' className={classes.text}>
                            <FormattedMessage id={`personal_dashboard.notification_box.messages.text.${user.unseen_messages > 0 ? 'present' : 'none'}`} />
                        </Typography>
                    </Box>
                    {user.unseen_messages > 0 &&
                        <Box className={classes.numberBox}>
                            {user.unseen_messages}
                        </Box>
                    }
                </MenuItem>
            </RouteMapLink>
            <Divider />
        </>}
        <RouteMapLink page={'users/candidacies'}>
            <MenuItem className={classes.itemBox}>
                <Box className={classes.textBox}>
                    <Typography variant='h6' className={classes.title}>
                        <FormattedMessage id={`personal_dashboard.notification_box.candidacies.title.${user.job_candidacies_from_last_30_days_count > 0 ? 'present' : 'none'}`} />
                    </Typography>
                    <Typography variant='body1' className={classes.text}>
                        <FormattedMessage id={`personal_dashboard.notification_box.candidacies.text.${user.job_candidacies_from_last_30_days_count > 0 ? 'present' : 'none'}`} />
                    </Typography>
                </Box>
                {user.job_candidacies_from_last_30_days_count > 0 &&
                    <Box className={classes.numberBox}>
                        {user.job_candidacies_from_last_30_days_count}
                    </Box>
                }
            </MenuItem>
        </RouteMapLink>
    </>)
}
export default NotificationBox
