import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    paper: {
        zIndex: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 'solid var(--foreground-silver) 1px',
        borderRadius: 50,
        padding: 2,
        marginTop: 24,
        marginBottom: 24,
        overflow: 'scroll',
        scrollbarWidth: 'none'
    },
    mainTabs: {
        display: 'flex',
        gap: 16
    },
    settingTabs: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4
    },
    icon: {
        fontSize: '24px',
        color: 'var(--black)',
        borderRadius: 50,
        padding: 9
    },
    link: {
        display: 'flex',
        alignItems: 'center'
    },
    messageIcon: {
        fontSize: '24px'
    },
    tab: {
        borderRadius: 50,
        padding: '6px 20px',
        fontSize: '16px !important'
    },
    active: {
        backgroundColor: 'var(--background-purple)',
        borderRadius: 50,
        '& span, & svg': {
            color: 'white !important'
        }
    }
}))
