import React, { useEffect, useState, useContext } from 'react'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import ProfilePassport from './components/ProfilePassport/ProfilePassport'
import RecommendedJobsForUserSection from './components/RecommendedJobsForUserSection/RecommendedJobsForUserSection'
import LastChanceJobs from './components/LastChanceJobs/LastChanceJobs'
import RecentlyViewedJobs from './components/RecentlyViewedJobs/RecentlyViewedJobs'
import RecruitingCompaniesForUser from './components/RecruitingCompaniesForUser/RecruitingCompaniesForUser'
import RecommendedArticlesForUser from './components/RecommendedArticlesForUser/RecommendedArticlesForUser'
import FavouriteJobs from './components/FavouriteJobs/FavouriteJobs'
import ProfileEditForm from './components/ProfileEditForm/ProfileEditForm'
import HomeSearch from 'components/HomeSearch/HomeSearch'
import { Container, Box, Hidden } from '@material-ui/core'
import { useStyles } from './PersonalDashboard_style'
import { UserContext } from 'contexts/UserContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import AdBanner from 'components/AdBanner/AdBanner'

const PersonalDashboardPage = (props) => {
    const { ads } = props
    const classes = useStyles()
    const userContext = useContext(UserContext)

    const initializeShowProfileEditFormStep = () => {
        if (userNameIsIncomplete()) return [0, 'name']
        if (userExperienceIsIncomplete()) return [2, 'experience']
        if (userContext.userHasNotSelectedAJobType()) return [3, 'status']
        if (userCityIsIncomplete()) return [4, 'location']
    }

    const userNameIsIncomplete = () => !userContext.user.first_name || !userContext.user.last_name
    const userCityIsIncomplete = () => !userContext.user.city

    const userExperienceIsIncomplete = () => {
        if (!userContext.user.current_experience) return true

        // Check if the experience has not been updated in the last year.
        const updatedAt = new Date(userContext.user.current_experience.updated_at)
        const currentDate = new Date()
        const timeDifference = currentDate - updatedAt
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000
        return timeDifference > oneYearInMilliseconds
    }

    const initializeShowProfileEditFormState = () => {
        if (userNameIsIncomplete()) return true
        if (userExperienceIsIncomplete()) return true
        if (userContext.userHasNotSelectedAJobType()) return true
        if (userCityIsIncomplete()) return true

        return false
    }

    const initStepNumber = initializeShowProfileEditFormStep()
    const [showProfileEditForm, setShowProfileEditForm] = useState(false)
    const [profileEditFormStep, setProfileEditFormStep] = useState(null)
    const [highlightInputField, setHighlightInputField] = useState(false)
    const [userInfoHasLoaded, setUserInfoHasLoaded] = useState(false)
    const [stepInitialized, setStepInitialized] = useState(false)

    useEffect(() => {
        userContext.getUserInfo()
    }, [])
    useEffect(() => {
        if (!userInfoHasLoaded && userContext.user.current_experience) {
            setShowProfileEditForm(initializeShowProfileEditFormState())
            setUserInfoHasLoaded(true)
        }
    }, [userContext.user.current_experience])
    useEffect(() => {
        if (initStepNumber && !stepInitialized) {
            setProfileEditFormStep(initStepNumber[0])
            setShowProfileEditForm(true)
            setStepInitialized(true)
        }
    }, [userContext.user])
    useEffect(() => {
        if (initStepNumber && initStepNumber[1]) AnalyticsHelper.sendGTMEvent(`Personal Dashboard show form ${initStepNumber[0]} ${initStepNumber[1]}`)
        AnalyticsHelper.sendGTMEvent('Personal Dashboard page view')
    }, [])

    const openProfileEditFormOnStep = (step, highlightField = false) => {
        setProfileEditFormStep(step)
        setShowProfileEditForm(true)
        setHighlightInputField(highlightField)
        window.scrollTo(0, 0)
    }

    return (<>
        <SharedTabs user={userContext.user} currentTab='users/dashboard' />
        {userContext.userLoaded && <>
            {showProfileEditForm &&
                <Hidden mdUp>
                    <ProfileEditForm
                        setShowProfileEditForm={setShowProfileEditForm}
                        step={profileEditFormStep}
                        setStep={setProfileEditFormStep}
                        highlightInputField={highlightInputField}
                    />
                </Hidden>
            }
            <Container maxWidth='lg' className={classes.pageContainer}>
                <Hidden smDown>
                    <Box className={classes.profilePassportBox}>
                        <ProfilePassport
                            context='personal_dashboard'
                            user={userContext.user}
                            pictureId={userContext.user.profile_picture_id}
                            openProfileEditFormOnStep={openProfileEditFormOnStep}
                        />
                    </Box>
                </Hidden>
                <Box className={classes.mainBox}>
                    {showProfileEditForm &&
                        <Hidden smDown>
                            <Box className={classes.sectionBox}>
                                <ProfileEditForm
                                    setShowProfileEditForm={setShowProfileEditForm}
                                    step={profileEditFormStep}
                                    setStep={setProfileEditFormStep}
                                    highlightInputField={highlightInputField}
                                />
                            </Box>
                        </Hidden>
                    }
                    <RecommendedJobsForUserSection
                        user={userContext.user}
                        openProfileEditFormOnStep={openProfileEditFormOnStep}
                    />
                    {ads &&
                        <Box className={classes.jobSectionBox}>
                            <AdBanner ads={ads} place='Personal_Dashboard_Leaderboard_1' device='desktop' width='100%' />
                            <AdBanner ads={ads} place='Personal_Dashboard_Mobile_1' device='mobile' width='100%' />
                        </Box>
                    }
                    <Box className={classes.jobSectionBox}>
                        <RecommendedArticlesForUser user={userContext.user} statusConfig={userContext.statusConfig} />
                    </Box>
                    <Box className={classes.jobSectionBox}>
                        <LastChanceJobs user={userContext.user} statusConfig={userContext.statusConfig} />
                    </Box>
                    <FavouriteJobs user={userContext.user} />
                    <Box className={classes.jobSectionBox}>
                        <RecentlyViewedJobs user={userContext.user} />
                    </Box>
                    <Box className={classes.sectionBox}>
                        <HomeSearch />
                    </Box>
                    <Box className={classes.jobSectionBox}>
                        <RecruitingCompaniesForUser user={userContext.user} />
                    </Box>
                    <Hidden mdUp>
                        <Box className={classes.profilePassportBox}>
                            <ProfilePassport user={userContext.user} openProfileEditFormOnStep={openProfileEditFormOnStep} />
                        </Box>
                    </Hidden>
                </Box>
            </Container>
        </>}
    </>)
}

export default PersonalDashboardPage
