import React, { useState, useRef, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './LanguageDropdown_style'
import { Box, Typography } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap'
import ArrowDownIcon from './icons/ArrowDownIcon'
import { OPTIONS } from './options'

const LanguageDropdown = (props) => {
	const { paramsForUrlTranslation, menuPosition } = props
	const containerRef = useRef(null)
	const classes = useStyles()
	const lang = RouteMap.GetLocaleFromUrl()
	const [isHover, setIsHover] = useState(false)
	const [isActive, setIsActive] = useState(false)

	const buildUrlWithLang = (lang) => {
		if (props.altLangUrls && (props.altLangUrls.current.replace(/\?.*/, '').replace(/\//g, '') === window.location.href.replace(/\?.*/, '').replace(/\//g, ''))) {
			return props.altLangUrls[lang] + (paramsForUrlTranslation || '')
		} else {
			const pathToUse = window.location.pathname.substring(4, window.location.pathname.length)
			return RouteMap.Page(pathToUse.replace(/\/$/g, ''), lang) + (paramsForUrlTranslation || '')
		}
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setIsActive(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside)

		return () => document.removeEventListener("mousedown", handleClickOutside)
	}, [containerRef])

	const handleMouseEnter = () => { setIsHover(true) }
	const handleMouseLeave = () => { setIsHover(false) }
	const handleClick = () => { setIsActive(!isActive) }

	return (
		<Box ref={containerRef} className={classes.container}>
			<Box
				className={classes.containerSelect}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleClick}
			>
				<Typography
					variant='h5'
					component='p'
					className={classes.label}
					style={{
						color: isHover ? 'var(--background-black)' : 'var(--background-white)',
					}}
				>
					{lang.toUpperCase()}
				</Typography>
				<ArrowDownIcon isHover={isHover} />
			</Box>
			{isActive && <Box className={[classes.containerOptions, menuPosition === 'top' && classes.containerOptionsTop]}>
				{OPTIONS.map((item) => (
					<RouteMapLink hreflang={item.value} color='white' redirectionUrl={buildUrlWithLang(item.value)}>
						<Typography variant='h5' component='p' className={classes.option}>
							<FormattedMessage id={item.label} />
						</Typography>
					</RouteMapLink>
				))}
			</Box>}
		</Box>
	)
}

export default LanguageDropdown
