import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './OtherCompanyJobs_style'
import { Box, Typography, Chip, useTheme } from '@material-ui/core'

import SVGFactory from 'icons/SVGFactory/SVGFactory'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const OtherCompanyJobs = (props) => {
    const { job } = props
    const jobsToRender = job?.company_jobs?.slice(0, 3)
    const classes = useStyles()
    const theme = useTheme()

    if (!job?.company_jobs || job?.company_jobs?.length === 0) return null

    return (
        <Box className={classes.OtherCompanyJobsContainer}>
            <Box className={classes.titleContainer}>
                <Box className={classes.iconContainer}>
                    <SVGFactory name='lightning' width='14px' height='14px' />
                </Box>
                <Typography
                    className={classes.title}
                    variant='h1'
                    component='h1'
                >
                    <FormattedMessage id='job_show.other_company_jobs.title' /> {job.company_name}
                </Typography>
            </Box>
            <Box className={classes.jobsContainer}>
                {jobsToRender.map(job =>
                    <RouteMapLink id={job.id} showItemType={job.type} showItemUrl={job.url} className={classes.jobLinkContainer}>
                        <Box className={classes.jobBox}>
                            <Typography
                                className={classes.jobTitle}
                                variant='h3'
                                component='h3'
                            >
                                {job?.title}
                            </Typography>
                            <Box className={classes.jobInfoContainer}>
                                {job?.job_tags?.length > 0 && <Box className={classes.tagsContainer}>
                                    {job.job_tags.map(tag =>
                                        <Chip
                                            classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                                            className={classes.chip}
                                            label={<FormattedMessage id={`job_card.tags.${tag}`} />}
                                            key={tag}
                                            name={tag}
                                            color={'var(--dark-grey)'}
                                        />
                                    )}
                                </Box>}
                                <Box className={classes.locationContainer}>
                                    <SVGFactory
                                        skipSvgWrapper
                                        name='location'
                                    />
                                    <Typography
                                        component='p'
                                        className={classes.location}
                                    >
                                        {job?.city}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </RouteMapLink>)}
            </Box>
            <Box className={classes.linkContainer}>
                <RouteMapLink
                    showItemType='company'
                    showItemUrl={job.company_url}
                    className={classes.link}
                >
                    <Typography variant='h1' component='p' className={classes.linkText}>
                        <FormattedMessage id='job_show.other_company_jobs.more_link' /> {job.company_name}
                    </Typography>
                    <Box className={classes.linkIconContainer}>
                        <IconFactory
                            icon='arrow-right'
                            color={theme.palette.fg['white']}
                            width='10px'
                            height='10px'
                        />
                    </Box>
                </RouteMapLink>
            </Box>
        </Box>
    )
}

export default OtherCompanyJobs
