import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './CTABannerPostJobs_style'
import { Typography, Container, Box } from '@material-ui/core'

import CTABannerButton from 'shared/components/CTABannerButton/CTABannerButton.jsx'
import { useWindowSize } from 'hooks/useWindowSize'

const CTABannerPostJobs = (props) => {
    const { userType, type } = props
    const classes = useStyles()
    const { isLargeScreen } = useWindowSize()

    return (
        <Container className={classes.bannerContainer} disableGutters>
            <Box className={classes.upContainer}>
                <FormattedMessage id={isLargeScreen ? 'banner_post_job.cta_banner_title_desktop' : 'banner_post_job.cta_banner_title_mobile'}>
                    {text =>
                        <Typography className={classes.titleContainer} variant='h1' component='p' dangerouslySetInnerHTML={{ __html: text }} />
                    }
                </FormattedMessage>
                <Typography className={classes.description}>  <FormattedMessage id='banner_post_job.description' /></Typography>
                <CTABannerButton userType={userType} type={type} textId='banner_post_job.button.label' />
            </Box>

            <Box className={classes.downContainer}>
                <Image
                    cloudName='studentbe'
                    publicId='student-be-assets/lady-blue-cta.png'
                    alt='student.be post 3 jobs for free'
                    className={classes.image}
                >
                    <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                </Image>
            </Box>
        </Container >

    )
}

export default CTABannerPostJobs
