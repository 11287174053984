/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './UploadDocument_style'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Box, Typography } from '@material-ui/core'

import CloudinaryHelper from 'assets/CloudinaryHelper'
import DateHandler from 'assets/DateHandler'
import DocumentAPI from 'api/DocumentAPI'
import Button from 'components/Button/Button'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const UploadDocument = (props) => {
    const { setImageArray, imageArray, profileId, page, documentIdToSend, setDocumentIdToSend } = props
    const classes = useStyles()
    const [cloudy, setCloudy] = useState()

    const openModal = () => cloudy.open()

    const handleDelete = (_, key) => {
        DocumentAPI.deleteDocumentImage(imageArray[key].id)

        const newDocumentArray = imageArray.filter((_, index) => index !== key)
        setImageArray(newDocumentArray)
    }

    const handleDocument = (event) => {
        page === 'applyPopup' && setDocumentIdToSend(event.target.value)
    }

    const getDocumentToSendId = () => {
        return documentIdToSend ? documentIdToSend.toString() : null
    }

    useEffect(() => {
        setCloudy(
            CloudinaryHelper.createDocumentUploadWidget({},
                (error, result) => {
                    if (!error && result && result.event === 'success') {
                        try {
                            const data = {
                                profile_id: profileId,
                                cloudinary_key: result.info.public_id,
                                name: result.info.original_filename,
                                format: result.info.format,
                                is_used: false,
                                authenticated: true,
                                is_cv: true
                            }
                            DocumentAPI.postDocument(data).then((response) => {
                                const newImageArray = [
                                    ...imageArray,
                                    response.data.document_object
                                ]
                                setImageArray(newImageArray)
                                page === 'applyPopup' && setDocumentIdToSend(response.data.document_object.id.toString())
                            })
                        } catch (e) {
                            alert('error database')
                            Bugsnag.notify(e)
                        }
                    }
                }
            ))
    }, [imageArray])

    return (<>
        {page === 'studentProfile' && imageArray.length > 0 &&
            <Box display={'flex'}>
                <Grid container spacing={1} alignItems={'center'}>
                    {imageArray.map((element, key) => {
                        return (
                            <Box
                                alignItems={'center'}
                                component={Grid}
                                container
                                key={element.id}
                                item
                                xs={12}
                                id={'imageCloundinary' + key.toString()}
                            >
                                <Grid item xs={10}>
                                    <Box className={classes.documentBox} >
                                        <p className={classes.documentText}>
                                            {element.name}
                                        </p>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                        window.open(element.asset_url)
                                    }}>
                                        <IconFactory icon='view' />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => { handleDelete(element, key) }}>
                                        <IconFactory icon='delete' />
                                    </IconButton>
                                </Grid>
                            </Box>
                        )
                    })}
                </Grid>
            </Box>
        }
        {page === 'applyPopup' &&
            <Box display={'flex'}>
                <Grid container spacing={1} alignItems={'center'}>
                    <RadioGroup name="documentCv" value={getDocumentToSendId()} onChange={handleDocument} className={classes.radioGroup}>
                        {imageArray.length > 0 && imageArray.map((element, key) => {
                            return (
                                <Box
                                    alignItems={'center'}
                                    component={Grid}
                                    container
                                    key={element.id}
                                    item
                                    xs={12}
                                    id={'imageCloundinary' + key.toString()}
                                    className={classes.radioContainer}
                                >
                                    <Grid item sm={6} xs={12}>
                                        <Box className={classes.documentBox}>
                                            <FormControlLabel value={element.id.toString()} control={<Radio color='primary' />} />
                                            <Typography className={classes.documentText}>
                                                {element.name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3} xs={8}>
                                        <FormattedMessage id='landing_upload.doc_date' values={{ date: DateHandler.ToBasicFormat(element.updated_at) }} />
                                    </Grid>
                                    <Grid item sm={1} xs={4} className={classes.buttonContainer}>
                                        <IconButton onClick={() => {
                                            window.open(element.asset_url)
                                        }}
                                        >
                                            <IconFactory icon='view' />
                                        </IconButton>
                                        <IconButton onClick={() => { handleDelete(element, key) }}>
                                            <IconFactory icon='delete' />
                                        </IconButton>
                                    </Grid>
                                </Box>
                            )
                        })}
                    </RadioGroup>
                </Grid>
            </Box>
        }

        {cloudy &&
            <Box className={classes.uploadButtonContainer}>
                <Button
                    variant='primary'
                    size='small'
                    endIcon={<IconFactory icon='add-file' />}
                    color='primary'
                    className={classes.button}
                    onClick={openModal}
                >
                    <FormattedMessage id="landing_upload.add_doc" />
                </Button>
            </Box>
        }
    </>)
}

export default UploadDocument
