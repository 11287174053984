import React from 'react'
import { useStyles } from './RecommendedJobsView_style'
import { Box, Typography } from '@material-ui/core'
import Button from 'shared/components/Button/Button.jsx'
import JobCardV2 from 'cards/JobCard/JobCardV2'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const RecommendedJobsView = (props) => {
    const { recommendedJobs, moreItemsURL, title, moreItemsText } = props
    const classes = useStyles()

    return (
        <Box className={classes.RecommendedJobsContainer} disableGutters>
            <Box className={classes.innerBox}>
                <Typography
                    className={classes.title}
                    component='h1'
                >
                    {title}
                </Typography>
                <Box className={classes.cardsContainer}>
                    {recommendedJobs.map(job => <JobCardV2 key={job.id} job={job} />)}
                </Box>
                <Box className={classes.buttonContainer}>
                    <RouteMapLink redirectionUrl={moreItemsURL}>
                        <Button variant='cta-primary'>
                            {moreItemsText}
                        </Button>
                    </RouteMapLink>
                </Box>
            </Box>
        </Box>
    )
}

export default RecommendedJobsView
