export const styles = (theme) => ({
    wrapper: {
        width: '100%',
        height: '352px',
        [theme.breakpoints.up('sm')]: {
            height: '352px',
        },
    },
    cardRoot: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.bg['white'],
        border: `1px solid ${theme.palette.fg['silver']}`,
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'unset'
    },
    mediaContainer: {
        width: '100%',
        height: '195px'
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    kotCardInfo: {
        width: '100%',
        flex: 1,
        padding: '8px 17px 17px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        rowGap: '10px'
    },
    title: {
        color: theme.palette.fg['black'],
        fontSize: '20px',
        lineHeight: '23px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        textOverflow: 'ellipsis',
    },
    downContainer: {
        width: '100%'
    },
    type: {
        paddingBottom: '7px',
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'capitalize'
    },
    kotDetails: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '10px'
    },
    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '21px'
    },
    roomInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '6px'
    },
    cityInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '8px'
    },
    infoText: {
        color: theme.palette.fg['black40Opacity'],
        fontSize: '12px',
        lineHeight: '15px',
        textTransform: 'capitalize'
    },
    rightContainer: {

    },
})
