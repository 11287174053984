import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './recentarticles_style.js'
import { Typography, Box } from '@material-ui/core'

import ArticleAPI from 'api/ArticleAPI'
import { useConstructor } from 'hooks/useConstructor'
import ArticleListCompactCard from 'cards/ArticleListCard/ArticleListCompactCard'

const RecentArticles = (props) => {
    const { count, category, offset_key } = props
    const classes = useStyles()
    const [articles, setArticles] = useState([])
    const [articlesLoaded, setArticlesLoaded] = useState(false)

    const recentArticleParams = {
        count: count || 4,
        category: category,
        offset_key: offset_key || 0
    }

    useConstructor(() => {
        try {
            ArticleAPI.GetRecentArticles(recentArticleParams)
                .then((response) => setArticles(response.data))
                .then(() => setArticlesLoaded(true))
        } catch (error) {
            console.error(error)
        }
    })

    const renderArticles = () => {
        const resultArray = []
        let i = 0
        if (articlesLoaded && articles && Array.isArray(articles)) {
            articles.forEach((article) => {
                i++
                resultArray.push(
                    <ArticleListCompactCard
                        key={article.title}
                        article={article}
                    />
                )
            })
        }
        return resultArray
    }

    return (
        <Box className={classes.boxContainer}>
            <Box className={classes.titleContainer}>
                <Typography variant='h1' component='h2' className={classes.titlePage}>
                    <FormattedMessage id="landing_recent_articles.news" />
                </Typography>
            </Box>
            <Box className={classes.renderArticles}>
                {articlesLoaded && renderArticles()}
            </Box>
        </Box>
    )
}

export default RecentArticles
