import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    multiSelectFilterViewContainer: {
        width: '100%',
    },

    multiSelectFilterViewFormControl: {
        width: '100%',
    },

    multiSelectFilterViewTrigger: {
        width: '100%',
        height: '49px',
        padding: '0px',
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['white'],
        borderRadius: '80px',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.bg['white'],
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
        },
        '&:hover $triggerPlaceholderText, &:hover $triggerArrowIcon': {
            color: theme.palette.fg['purple'],
        },
    },

    multiSelectFilterViewTriggerClicked: {
        boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
    },

    select: {
        padding: '0 !important',
        width: '100%',
        height: '100%',
    },

    selectInput: {
        width: '100%',
    },

    // Placeholder
    triggerPlaceholderContainer: {
        height: '49px',
        padding: '0px 20px 0px 18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '5px',
    },

    triggerPlaceholderText: {
        textTransform: 'none',
        color: theme.palette.fg['greyDark'],
        fontFamily: 'GreedStandard-Regular',
        fontSize: '16px',
        lineHeight: '18px',
    },

    triggerArrowIcon: {
        color: theme.palette.fg['black60Opacity'],
    },

    tagAvatar: {
        fontFamily: 'GreedStandard-Bold',
        fontSize: 14,
        width: 20,
        height: 18,
        color: theme.palette.fg['white'],
        backgroundColor: theme.palette.bg['black'],
        marginLeft: 8,
    },

    menuHeader: {
        width: '100%',
        padding: '0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderBottom: `1px solid ${theme.palette.fg['beigeLight']}`,
    },

    closeIconButton: {
        height: 'fit-content',
        paddingLeft: '0px',
        paddingRight: '0px',
        '& svg': {
            width: 18,
        },
    },

    menuItem: {
        padding: '10px 24px',
        '&:hover': {
            backgroundColor: theme.palette.bg['white'],
            color: theme.palette.fg['purple'],
        },
    },

    checkbox: {
        marginRight: 3,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}))
