import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        width: '100%',
        maxWidth: '1440px',
        paddingTop: '38px',
        backgroundColor: theme.palette.bg['purple'],
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '0px'
        }
    },

    upContainer: {
        width: '100%',
        padding: '0px 20px 5px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            flex: 1,
            paddingRight: '0px',
            paddingBottom: '54px',
            alignItems: 'flex-start'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '80px'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '950px'
        }
    },

    titleContainer: {
        paddingBottom: '16px',
        color: theme.palette.fg['white'],
        fontSize: '65px',
        lineHeight: '77px',
        textAlign: 'center',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: '75px',
            textAlign: 'left'
        },
        '& .GreedWideLight': {
            fontFamily: 'GreedWide-Light',
        },
        '& .GreedCondensedHeavy': {
            fontFamily: 'GreedCondensed-Heavy',
        },
        '& .GreedCondensedRegular': {
            fontFamily: 'GreedCondensed-Regular',
        },
        '& .GreedNarrowHeavy': {
            fontFamily: 'GreedNarrow-Heavy',
        },
    },



    description: {
        maxWidth: '380px',
        padding: '0px 20px 32px 20px',
        color: theme.palette.fg['white'],
        textAlign: 'center',
        lineHeight: '18px',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
            padding: '0px 0px 32px'
        }
    },

    downContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.up('lg')]: {
            width: 'fit-content'
        }
    },

    image: {
        width: '100%',
        height: '404px',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
            height: '520px',
            maxWidth: '603px'
        }
    }
}))
