import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './LetsChatCard_style'
import { Box, Typography } from '@material-ui/core'

import RouteMap from 'assets/RouteMap.js'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import Button from 'shared/components/Button/Button.jsx'

const LetsChatCard = (props) => {
    const { job, user, handleOpenChat, openLoginRegisterModal, unavailable, openChat } = props
    const classes = useStyles()
    if (!job.chat_only && !job.alreadyApplied) return null
    if (job.application_url) return null
    if (!job.active) return null
    if (unavailable) return null
    if (user && ['employer', 'kot_owner'].includes(user.type)) return null

    const chatLink = RouteMap.Page(job.chat_url)
    let chatText = 'job_chat.button'
    let onClick = () => undefined
    if (user && user.type === 'student') {
        if (job.alreadyApplied || job.chatCreated) {
            onClick = () => { window.location.href = chatLink }
            chatText = 'transl_internship.view_chat'
        } else {
            onClick = () => {
                handleOpenChat(!openChat)
                AnalyticsHelper.sendGTMEvent('employer-chat-click')
            }
        }
    } else {
        onClick = () => {
            openLoginRegisterModal()
        }
    }

    return (
        <Box className={classes.letsChatContainer}>
            <Typography variant='h1' component='h1' className={classes.title}>
                <FormattedMessage id="job_chat.title" />
            </Typography>
            <Typography component='p' className={classes.message}>
                <FormattedMessage id="job_chat.message" />
            </Typography>
            <Button variant='cta-primary' size='small' className={classes.submitButton} onClick={onClick}>
                <FormattedMessage id={chatText} />
            </Button>
        </Box>
    )
}

export default LetsChatCard
