/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'

import { useStyles } from './homestudentmain_style'
import { Hidden } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import AlertsAPI from 'api/AlertsAPI.js'
import FeedbackFactory from 'components/FeedbackFactory/FeedbackFactory'
import HomeBanner from './components/HomeBanner/HomeBanner'
import HomeCompanyFilter from './components/HomeCompanyFilter/HomeCompanyFilter'
import HomeStats from './components/HomeStats/HomeStats'
import PopupFactory from 'modals/Popup/PopupFactory'
import CompanyImpact from './components/CompanyImpact/CompanyImpact'
import CTADashboard from '../../shared/components/CTADashboard/CTADashboard'

const HomeStudentMainPage = (props) => {
    const { user, lastCompanies } = props
    const classes = useStyles()
    const [showAlertPopup, setShowAlertPopup] = useState(false)
    const [alertOrigin, setAlertOrigin] = useState(null)
    const feedbackRef = useRef()
    const filters = {
        studyDomain: [],
        location: [],
        companySize: [],
        sector: []
    }

    useEffect(() => {
        // eslint-disable-next-line no-undef
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    }, [])

    const renderAlertPopup = (origin = null) => {
        setShowAlertPopup(!showAlertPopup)
        setAlertOrigin(origin)
    }

    const createAlert = () => {
        AlertsAPI.CreateAlert({
            study_domains: filters.studyDomain,
            locations: filters.location,
            company_sizes: filters.companySize,
            sectors: filters.sector,
            job_type: 'first_job',
            schedules: filters.jobSchedule,
            creation_origin: alertOrigin
        }).then((response) => {
            renderAlertPopup()
            feedbackRef.current.triggerFeedback(true, <FormattedMessage id={'create_alert_popup.alert_creation_success'} />)
        },
        error => {
            renderAlertPopup()
            feedbackRef.current.triggerFeedback(false, <FormattedMessage id={'create_alert_popup.alert_creation_failure'} /> + error)
        })
    }

    return (
        <Box className={classes.homeStudentMain}>
            <PopupFactory
                type="create-alert-popup"
                popupExit={() => renderAlertPopup()}
                actionPopup={createAlert}
                openShare={showAlertPopup}
                filters={filters}
                jobType='first_job'
            />
            <FeedbackFactory
                ref={feedbackRef}
            />
            {/* HOME BANNER */}
            <HomeBanner />
            {/* STATS */}
            <Hidden mdDown>
                <Box className={classes.backgroundWhite}>
                    <Container maxWidth='lg' disableGutters className={classes.homeStatsContainer}>
                        <HomeStats />
                    </Container>
                </Box>
            </Hidden>
            {/* QUICK FILTER */}
            <Box>
                <Container maxWidth='lg' disableGutters className={classes.container}>
                    <HomeCompanyFilter />
                </Container>
            </Box>
            <CTADashboard user={user} />
            {/* COMPANY IMPACT */}
            <Box>
                <Container maxWidth='lg' disableGutters className={classes.container}>
                    <CompanyImpact lastCompanies={lastCompanies}/>
                </Container>
            </Box>
        </Box>
    )
}
export default HomeStudentMainPage
