import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    checkboxLine: {
        paddingBottom: '15px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '27px',
        }
    },
    checkboxText: {
        fontFamily: 'GreedStandard-Medium',
        color: theme.palette.fg['black'],
        fontSize: '16px',
        paddingLeft: '13px',
    },
    checkbox: {
        overflow: 'hidden',
        width: '20px',
        height: '19px',
        backgroundColor: theme.palette.bg['white'],
        border: `2px solid ${theme.palette.fg['black']}`,
        borderRadius: '5px',
        padding: 0,
    }
}))
