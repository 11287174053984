import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    viewMonitor: {
        height: '30px',
        [theme.breakpoints.up('md')]: {
            height: '58px',
        }
    },
    jobShowBanner: {
        backgroundColor: theme.palette.bg['white'],
        zIndex: 9,
        position: 'sticky',
        top: 0,
    },
    imageBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        maxHeight: 200,
        overflow: 'hidden'
    },
    image: {
        width: '100%'
    },
    bannerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16,
        paddingTop: 32,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            flexDirection: 'column'
        }
    },
    bannerContainerSticky: {
        marginBottom: 12,
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
            paddingTop: 16
        }
    },
    titleBoxSticky: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 12,
            marginBottom: 12
        },
        gap: 12
    },
    infoBannerContainer: {
        display: 'flex'
    },
    ctaBox: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            marginTop: 24
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 12
        }
    },
    ctaBoxSticky: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center'
        }
    },
    logoBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        maxWidth: 108,
        maxHeight: 108,
        borderRadius: 14,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 52,
            maxHeight: 52,
            height: 'fit-content',
            borderRadius: 12
        }
    },
    logoSticky: {
        maxHeight: 36
    },
    jobInfoContainer: {
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 12
        }
    },
    jobTitleBox: {
        fontFamily: 'GintoNord-Bold',
        fontSize: 20,
        lineHeight: '26.18px',
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 12,
            fontSize: 14,
            lineHeight: '15.71px'
        }
    },
    jobTitleBoxSticky: {
        fontSize: 12,
        lineHeight: '18px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 0
        }

    },
    companyNameBox: {
        fontFamily: 'GreedStandard-Bold',
        fontSize: 14,
        lineHeight: '18.33px',
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    companyNameBoxSticky: {
        fontSize: 12
    },
    tagBox: {
        display: 'flex',
        gap: 8,
        width: '100%',
        flexWrap: 'wrap'
    },
    tag: {
        padding: '6px 4px',
        backgroundColor: 'var(--beige)',
        borderRadius: 14,
        fontFamily: "'GreedStandard-Regular', sans-serif",
        fontSize: 12,
        lineHeight: '16px'
    },
    iconContainer: {
        marginLeft: 4,
        marginRight: 12
    },
    applyButton: {
        minWidth: 180
    },
    boosted: {
        marginLeft: 8,
        background: 'var(--green) !important',
        color: 'white !important',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: 8
        }
    },
    boostable: {
        marginLeft: 8,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: 8
        }
    }
}))
