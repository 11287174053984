import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobShowCompanyImages_style'
import { Box, Typography } from '@material-ui/core'

import SVGFactory from 'icons/SVGFactory/SVGFactory'

const JobShowCompanyImages = (props) => {
    const { images } = props
    const imagesToRender = images?.slice(0, 3)
    const classes = useStyles()

    if (!images || images?.length === 0) return null

    return (
        <Box className={classes.JobShowCompanyImagesContainer}>
            <Box className={classes.titleContainer}>
                <Box className={classes.iconContainer}>
                    <SVGFactory name='lightning' width='14px' height='14px' />
                </Box>
                <Typography
                    className={classes.title}
                    variant='h1'
                    component='h1'
                >
                    <FormattedMessage id='job_show.company_images.title' />
                </Typography>
            </Box>
            <Box className={classes.imagesContainer}>
                {imagesToRender.map(image => <Box key={image.id} className={classes.imageContainer}>
                    <Image
                        className={classes.image}
                        cloudName="studentbe"
                        publicId={image.cloudinary_key}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" quality="85" crop="scale" />
                    </Image>
                </Box>)}
            </Box>
        </Box>
    )
}

export default JobShowCompanyImages
