/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useRef, useState } from 'react'

import KotPopup from './KotPopup/KotPopup'
import SharePopup from './SharePopup/SharePopup'
import ShowDocumentPopup from './ShowDocumentPopup/ShowDocumentPopup'
import DeleteOfferPopup from './DeleteOfferPopup/DeleteOfferPopup'

import CompanyPicturesManagerPopup from './CompanyPicturesManagerPopup/CompanyPicturesManagerPopup'
import ApplyPopup from './ApplyPopup/AppluPopup'
import DangerZonePopup from './DangerZonePopup/DangerZonePopup'
import CreateAlertPopup from './CreateAlertPopup/CreateAlertPopup'

import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'

import Button from 'components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'

const PopupFactory = (props) => {
    const theme = useTheme()
    const popupRef = useRef()

    const [popupContent, setPopupContent] = useState({
        popupTitle: 'default',
        popupActionButton: 'default',
        popupCancelButton: 'default',
        onlyClose: false,
        doublebuttons: false,
        inversedActions: false,
        noAction: false,
        maxWidth: 'sm'
    })

    const handleClose = () => {
        props.popupExit()
    }

    const retrievePopupContent = (newPopupContent) => {
        setPopupContent({
            ...popupContent,
            ...newPopupContent
        })
    }

    const renderPopup = () => {
        switch (props.type) {
        case 'kot-offer-message':
            return <KotPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'share-offer':
            return <SharePopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'show-document':
            return <ShowDocumentPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'delete-offer':
            return <DeleteOfferPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'manage-company-pictures':
            return <CompanyPicturesManagerPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'apply-popup':
            return <ApplyPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'danger-zone-popup':
            return <DangerZonePopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'create-alert-popup':
            return <CreateAlertPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        default:
            return <p>Something went wrong</p>
        }
    }

    const renderButtonsActions = () => {
        if (!popupContent.noAction) {
            if (popupContent.doublebuttons) {
                return (
                    <Box>
                        <Button
                            onClick={popupContent.inversedActions ? popupContent.actionPopup : handleClose}
                            size='small'
                            variant='secondary'
                        >
                            {popupContent[popupContent.inversedActions ? 'popupActionButton' : 'popupCancelButton']}
                        </Button>
                        <Button
                            size='small'
                            variant='primary'
                            onClick={popupContent.inversedActions ? handleClose : popupContent.actionPopup}
                            style={{ marginLeft: 10 }}
                        >
                            {popupContent[popupContent.inversedActions ? 'popupCancelButton' : 'popupActionButton']}
                        </Button>
                    </Box>
                )
            } else if (popupContent.onlyClose) {
                return (
                    <Button
                        size='small'
                        variant='primary'
                        onClick={handleClose}
                    >
                        {popupContent.popupCancelButton}
                    </Button>
                )
            } else {
                return (
                    <Box style={{ paddingBottom: 20, display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Button
                            size='small'
                            variant='primary'
                            onClick={popupContent.actionPopup}
                        >
                            {popupContent.popupActionButton}
                        </Button>
                    </Box>

                )
            }
        }
    }

    return (
        <Dialog
            maxWidth={popupContent.maxWidth}
            open={props.openShare}
            onClose={handleClose}
            disableScrollLock={true}
            aria-labelledby="responsive-dialog-delete"
            fullWidth
            PaperProps={{ style: { borderRadius: 20 }}}
        >
            <DialogTitle onClose={handleClose}>
                <Typography
                    variant='h2'
                    style={{ marginTop: '1rem' }}
                >
                    {popupContent.popupTitle}
                </Typography>
                <IconButton aria-label="close" onClick={handleClose} style={{
                    position: 'absolute',
                    right: theme.spacing(1),
                    top: 5,
                    color: theme.palette.grey[500],
                    height: 35
                }}>
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box my={2}>
                    {renderPopup()}
                </Box>
            </DialogContent>

            <DialogActions>
                {renderButtonsActions()}
            </DialogActions>

        </Dialog>
    )
}

export default PopupFactory
