import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 16
    },
    saveButton: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    largeAvatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        cursor: 'pointer'
    },
    profileMainInfoContainer: {
        height: 144
    },
    profileMainBackground: {
        display: 'flex',
        alignItems: 'center',
        height: 100,
        backgroundColor: 'var(--beige)'
    },
    avatarContainer: {
        marginTop: 50,
        marginLeft: 15
    },
    profileNameContainer: {
        marginLeft: 15
    },
    nameMargin: {
        marginBottom: '-5px'
    },
    studentType: {
        color: 'var(--red)'
    },
    profileText: {
        textTransform: 'capitalize'
    },
    lastName: {
        fontSize: 16
    },
    firstName: {
        fontSize: 18
    },
    checkBox: {
        color: 'var(--dark-grey)'
    },
    gridFlex: {
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'end'
    },
    formControl: {
        width: '100%'
    },
    datePicker: {
        color: 'white',
        textColor: 'white',
        calendarTextColor: 'white',
        selectColor: 'white',
        selectTextColor: 'white',
        calendarYearBackgroundColor: 'white',
        headerColor: 'white'
    },
    button: {
        backgroundColor: 'var(--red)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'var(--red)'
        }
    },
    progressBar: {
        color: 'var(--red)'
    },
    iconColor: {
        color: 'var(--red)'
    },
    buttonOutlined: {
        color: 'var(--red)',
        borderColor: 'var(--red)'
    }
}))
