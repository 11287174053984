import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './TextFilterView_style'
import { Box, TextField, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const TextFilterView = (props) => {
    const { handleInputChange, keyPress, filters, filterTextId, minWidth, modal } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Box className={`${classes.textInputContainer}`}>

            <IconFactory icon='search-regular' color={theme.palette.fg['black']} className={classes.searchIcon} />
            <FormattedMessage id={filterTextId}>
                {placeholder =>
                    <TextField
                        InputProps={{ classes: { input: `${classes.textInput}` }, disableUnderline: true }}
                        placeholder={placeholder}
                        value={filters.title}
                        name='title'
                        onChange={handleInputChange}
                        fullWidth
                        onKeyPress={keyPress}
                    />
                }
            </FormattedMessage>
        </Box>
    )
}

export default TextFilterView
