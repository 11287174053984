import React from 'react'
import RouteMap from 'assets/RouteMap'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { useStyles, selectPaperStyle } from './LangSelect_style'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const langSelect = (props) => {
    const { paramsForUrlTranslation, color } = props
    const lang = RouteMap.GetLocaleFromUrl()
    const colorValue = color || 'var(--black)'
    const classes = useStyles(colorValue)

    const buildUrlWithLang = (lang) => {
        if (props.altLangUrls && (props.altLangUrls.current.replace(/\?.*/, '').replace(/\//g, '') === window.location.href.replace(/\?.*/, '').replace(/\//g, ''))) {
            return props.altLangUrls[lang] + (paramsForUrlTranslation || '')
        } else {
            const pathToUse = window.location.pathname.substring(4, window.location.pathname.length)
            return RouteMap.Page(pathToUse.replace(/\/$/g, ''), lang) + (paramsForUrlTranslation || '')
        }
    }

    const renderLangSelect = () => {
        if (props.allLangsFlat) {
            return (
                <Box className={classes.langsFlatten}>
                    <RouteMapLink hreflang='fr' color='white' redirectionUrl={buildUrlWithLang('fr')}>
                        FR
                    </RouteMapLink>
                    <span className={classes.linkSeperator}>{' '}</span>
                    <RouteMapLink hreflang='en' color='white' redirectionUrl={buildUrlWithLang('en')}>
                        EN
                    </RouteMapLink>
                    <span className={classes.linkSeperator}>{' '}</span>
                    <RouteMapLink hreflang='nl' color='white' redirectionUrl={buildUrlWithLang('nl')}>
                        NL
                    </RouteMapLink>
                </Box>
            )
        } else {
            return (
                <Select
                    value={lang}
                    style={{ height: '2.25rem' }}
                    disableUnderline
                    className={classes.select}
                    inputProps={{
                        MenuProps: { disableScrollLock: true },
                        classes: {
                            icon: classes.icon
                        }
                    }}
                    renderValue={(selected) => {
                        return <Typography variant='h4' component='div' style={{ color: color }}>{selected.toUpperCase()}</Typography>
                    }}
                    MenuProps={selectPaperStyle()}
                >
                    <RouteMapLink hreflang='fr' redirectionUrl={buildUrlWithLang('fr')}>
                        <MenuItem value='fr'><Typography variant='body2' className={classes.langTypography}>FR</Typography></MenuItem>
                    </RouteMapLink>
                    <RouteMapLink hreflang='en' redirectionUrl={buildUrlWithLang('en')}>
                        <MenuItem value='en'><Typography variant='body2' className={classes.langTypography}>EN</Typography></MenuItem>
                    </RouteMapLink>
                    <RouteMapLink hreflang='nl' redirectionUrl={buildUrlWithLang('nl')}>
                        <MenuItem value='nl'><Typography variant='body2' className={classes.langTypography}>NL</Typography></MenuItem>
                    </RouteMapLink>
                </Select>
            )
        }
    }

    return (
        <div className={classes.root}>
            {renderLangSelect()}
        </div>
    )
}

export default langSelect