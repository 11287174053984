/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUnicorn } from '@fortawesome/pro-light-svg-icons/faUnicorn'
import { faHeart, faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons/faHeart'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash'
import { faShareAlt } from '@fortawesome/pro-light-svg-icons/faShareAlt'
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faToggleOn } from '@fortawesome/pro-light-svg-icons/faToggleOn'
import { faToggleOff } from '@fortawesome/pro-light-svg-icons/faToggleOff'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faGauge } from '@fortawesome/pro-light-svg-icons/faGauge'
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons/faSignOutAlt'
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faSearch as faSearchRegular } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faScaleBalanced } from '@fortawesome/pro-light-svg-icons'
import { faCalculatorSimple } from '@fortawesome/pro-light-svg-icons'
import { faChartMixed } from '@fortawesome/pro-light-svg-icons'
import { faMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons'
import { faBusinessTime } from '@fortawesome/pro-light-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronLeft as faChevronLeftRegular } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRightRegular } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronDown as faChevronDownSolid } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faChevronUp as faChevronUpSolid } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons/faChevronCircleLeft'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight'
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave'
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'
import { faChalkboardTeacher } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faFilePlus } from '@fortawesome/pro-light-svg-icons/faFilePlus'
import { faFolderPlus } from '@fortawesome/pro-regular-svg-icons/faFolderPlus'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle'
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments'
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment'
import { faRadiationAlt } from '@fortawesome/pro-light-svg-icons/faRadiationAlt'
import { faBells } from '@fortawesome/pro-light-svg-icons/faBells'
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell'
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faRocketLaunch } from '@fortawesome/pro-light-svg-icons/faRocketLaunch'
import { faNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper'
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase'
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faLandmark } from '@fortawesome/pro-light-svg-icons'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faCheck as faCheckSolid } from '@fortawesome/free-solid-svg-icons'
import { faCheck as faCheckRegular } from '@fortawesome/pro-regular-svg-icons'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons/faCheckDouble'
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare'
import { faCheckSquare as faCheckSquareSolid } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare'
import { faScroll } from '@fortawesome/pro-light-svg-icons/faScroll'
import { faPhoneAlt } from '@fortawesome/pro-light-svg-icons/faPhoneAlt'
import { faCarSide } from '@fortawesome/pro-light-svg-icons/faCarSide'
import { faCopyright } from '@fortawesome/pro-light-svg-icons/faCopyright'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import { faArrowToTop } from '@fortawesome/pro-light-svg-icons/faArrowToTop'
import { faDotCircle } from '@fortawesome/pro-light-svg-icons/faDotCircle'
import { faBicycle } from '@fortawesome/pro-light-svg-icons/faBicycle'
import { faSteeringWheel } from '@fortawesome/pro-light-svg-icons/faSteeringWheel'
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH'
import { faSlidersH as faSlidersHRegular } from '@fortawesome/pro-regular-svg-icons/faSlidersH'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink'
import { faClock, faPiggyBank, faGraduationCap } from '@fortawesome/pro-light-svg-icons'
import { faRedoAlt } from '@fortawesome/pro-regular-svg-icons/faRedoAlt'
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload'
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot'
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard'
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList'
import { faFunction } from '@fortawesome/pro-light-svg-icons/faFunction'
import { faCircleEuro } from '@fortawesome/pro-light-svg-icons/faCircleEuro'
import { faBadgeDollar } from '@fortawesome/pro-regular-svg-icons/faBadgeDollar'
import { faBullhorn } from '@fortawesome/pro-light-svg-icons'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons'
import { faHeartbeat } from '@fortawesome/pro-light-svg-icons'
import { faUserFriends } from '@fortawesome/pro-light-svg-icons'
import { faHeadSideBrain } from '@fortawesome/pro-light-svg-icons'
import { faMoon } from '@fortawesome/pro-light-svg-icons'
import { faAtomAlt } from '@fortawesome/pro-light-svg-icons'
import { faTools } from '@fortawesome/pro-light-svg-icons'
import { faHashtag } from '@fortawesome/pro-light-svg-icons'
import { faSchool } from '@fortawesome/pro-light-svg-icons'
import { faPencilPaintbrush } from '@fortawesome/pro-light-svg-icons'
import { faGrinStars } from '@fortawesome/pro-light-svg-icons'
import {
    faBackpack, faUmbrellaBeach, faLeafHeart,
    faStarShooting, faHandshake, faGlobeEurope, faGlobeAfrica,
    faForkKnife, faCashRegister, faCarrot,
    faCalendarStar, faVacuum, faLaptopCode, faUserChart, faBook, faUniversity, faBolt
} from '@fortawesome/pro-light-svg-icons'
import { faBedFront } from '@fortawesome/pro-light-svg-icons'

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle'
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons/faHeart'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons/faStar'
import { faBell as faBellSolid } from '@fortawesome/free-solid-svg-icons/faBell'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight'
import { faTimesCircle as faSolidTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faMessage }from '@fortawesome/pro-light-svg-icons/faMessage'

const IconSwitch = (iconType) => {
    switch (iconType) {
    case 'heart': return faHeart
    case 'heart-full': return faHeartSolid
    case 'heart-regular': return faHeartRegular
    case 'heart-light': return faHeartLight
    case 'view': return faEye
    case 'eye': return faEye
    case 'eye-slash': return faEyeSlash
    case 'share': return faShareAlt
    case 'pen': return faPen
    case 'edit': return faPen
    case 'duplicate': return faCopy
    case 'delete': return faTrash
    case 'toggle-on': return faToggleOn
    case 'toggle-off': return faToggleOff
    case 'settings': return faCog
    case 'sign-out': return faSignOutAlt
    case 'account': return faUserCircle
    case 'mail': return faEnvelope
    case 'bars': return faBars
    case 'search': return faSearch
    case 'search-regular': return faSearchRegular
    case 'marker': return faMapMarkerAlt
    case 'star': return faStar
    case 'star-full': return faStarSolid
    case 'close': case 'cross': return faTimes
    case 'cross-circle': return faTimesCircle
    case 'cross-solid-circle': return faSolidTimesCircle
    case 'scale-balanced': return faScaleBalanced
    case 'calculator': return faCalculatorSimple
    case 'chart-mixed': return faChartMixed
    case 'money-bill-transfer': return faMoneyBillTransfer
    case 'business-time': return faBusinessTime
    case 'circle-check': return faCheckCircle
    case 'back': case 'left': return faChevronLeft
    case 'forward': case 'right': return faChevronRight
    case 'down': case 'more': return faChevronDown
    case 'down-solid': return faChevronDownSolid
    case 'up': return faChevronUp
    case 'up-solid': return faChevronUpSolid
    case 'save': return faSave
    case 'date': return faCalendarDay
    case 'calendar-week': return faCalendarWeek
    case 'calendar': return faCalendar
    case 'calendar-plus': return faCalendarPlus
    case 'circle': case 'radio-false': return faCircle
    case 'circle-full': case 'radio-true': return faDotCircle
    case 'circle-solid-full': return faCircleSolid
    case 'add-file': return faFilePlus
    case 'folder-plus': return faFolderPlus
    case 'file': return faFileAlt
    case 'add': return faPlus
    case 'plus-circle': return faPlusCircle
    case 'message': case 'chat': return faComments
    case 'comment': return faComment
    case 'danger': return faRadiationAlt
    case 'alerts': return faBells
    case 'alert': return faBell
    case 'alert-full': return faBellSolid
    case 'kot': return faHouse
    case 'user': return faUser
    case 'rocket': return faRocketLaunch
    case 'articles': return faNewspaper
    case 'ads': return faBriefcase
    case 'company': return faBuilding
    case 'send': return faPaperPlane
    case 'attachment': return faPaperclip
    case 'angle-left': return faAngleLeft
    case 'angle-right': return faAngleRight
    case 'angle-up': return faAngleUp
    case 'angle-down': return faAngleDown
    case 'check': return faCheck
    case 'check-solid': return faCheckSolid
    case 'check-regular': return faCheckRegular
    case 'double-check': return faCheckDouble
    case 'checkbox-true': return faCheckSquare
    case 'checkbox-true-filled': return faCheckSquareSolid
    case 'checkbox-false': return faSquare
    case 'backpack': return faBackpack
    case 'umbrella-beach': return faUmbrellaBeach
    case 'leaf-heart': return faLeafHeart
    case 'star-shooting': return faStarShooting
    case 'handshake': return faHandshake
    case 'landmark': return faLandmark
    case 'globe-europe': return faGlobeEurope
    case 'globe-africa': return faGlobeAfrica
    case 'scroll': return faScroll
    case 'phone': return faPhoneAlt
    case 'car': return faCarSide
    case 'facebook': return faFacebookSquare
    case 'messenger': return faFacebookMessenger
    case 'teacher': return faChalkboardTeacher
    case 'whatsapp': return faWhatsapp
    case 'twitter': return faTwitter
    case 'instagram': return faInstagram
    case 'tiktok': return faTiktok
    case 'linkedin': return faLinkedin
    case 'copyright': return faCopyright
    case 'question-circle': return faQuestionCircle
    case 'to-top': return faArrowToTop
    case 'bicycle': return faBicycle
    case 'driving-licence': return faSteeringWheel
    case 'candidacies': return faUsers
    case 'sliders': return faSlidersH
    case 'sliders-regular': return faSlidersHRegular
    case 'unicorn': return faUnicorn
    case 'moon': return faMoon
    case 'expand': return faExternalLink
    case 'clock': return faClock
    case 'reset': return faRedoAlt
    case 'arrow-right': return faArrowRight
    case 'arrow-left': return faArrowLeft
    case 'left-regular': return faChevronLeftRegular
    case 'right-regular': return faChevronRightRegular
    case 'student-job':
    case 'piggy-bank':
        return faPiggyBank
    case 'first-job': return faRocketLaunch
    case 'internship':
    case 'graduation-cap':
        return faGraduationCap
    case 'download': return faDownload
    case 'location-pin': return faLocationDot
    case 'licence': return faIdCard
    case 'clipboard': return faClipboardList
    case 'function': return faFunction
    case 'euro': return faCircleEuro
    case 'badge-dollar': return faBadgeDollar
    case 'dashboard': return faGauge
    case 'fork-knife': return faForkKnife
    case 'speaker': return faBullhorn
    case 'laptop-code': return faLaptopCode
    case 'user-chart': return faUserChart
    case 'cog': return faCog
    case 'heartbeat': return faHeartbeat
    case 'user-friends': return faUserFriends
    case 'head-side-brain': return faHeadSideBrain
    case 'atom-alt': return faAtomAlt
    case 'tools': return faTools
    case 'hashtag': return faHashtag
    case 'school': return faSchool
    case 'pencil-paintbrush': return faPencilPaintbrush
    case 'happy': return faGrinStars
    case 'register': return faCashRegister
    case 'carrot': return faCarrot
    case 'calendar-star': return faCalendarStar
    case 'vacuum': return faVacuum
    case 'image': return faImage
    case 'bed-front': return faBedFront
    case 'book': return faBook
    case 'university': return faUniversity
    case 'bolt': return faBolt
    case 'circle-arrow-left': return faArrowCircleLeft
    case 'circle-arrow-right': return faArrowCircleRight
    case 'conversation': return faMessage

    default: return '/'
    }
}

/*
    ===== DOC =====

        Usage if you need a tooltip:

        <IconFactory tooltip={tooltip name} icon={icon name} />
        Ex: <IconFactory tooltip="Partager" icon="share" />

        WithOut tooltip:

        <IconFactory type="button" icon="share" />

    ===== END =====
*/

const IconFactory = (props) => {
    const style = {
        fontSize: props.fontSize ? props.fontSize : '18px',
        color: props.color ? props.color : '',
        ...props.style
    }

    const renderIconButton = () => {
        return (
            <IconButton
                onClick={props.onClick}
                className={props.iconButtonClassName}
                style={{ padding: props.padding || '12px', cursor: 'pointer' }}
            >
                <FontAwesomeIcon {...props} onClick={null} icon={ IconSwitch(props.icon) } style={style}/>
            </IconButton>
        )
    }

    if (props.type === 'button' || props.button) {
        return renderIconButton()
    }

    if (props.tooltip) {
        return (
            <Tooltip title={props.tooltip}>
                {renderIconButton()}
            </Tooltip>
        )
    }

    return (
        <FontAwesomeIcon {...props} icon={ IconSwitch(props.icon) } style={style}/>
    )
}

IconFactory.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
    tooltip: PropTypes.string,
    className: PropTypes.string,
    fontSize: PropTypes.string,
    onClick: PropTypes.func
}

export default IconFactory
