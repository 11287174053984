import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 10,
        padding: '10px 0px',
        borderTop: '1px solid',
        borderColor: 'var(--light-grey)'
    },
    button: {
        width: '80%',
        backgroundColor: 'var(--red)',
        color: 'white',
        borderRadius: 8,
        fontFamily: "'GreedStandard-Medium', sans-serif !important",
        '&:hover': {
            backgroundColor: 'var(--red)'
        }
    }
}))
