/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FormControl, MenuItem, Select, useTheme } from '@material-ui/core'
import { useStyles } from './SelectFilter_style'
import IconFactory from 'icons/IconFactory/IconFactory'

const OpenIcon = (props) => {
    const { open } = props

    if (open) {
        return <IconFactory icon='up-solid' fontSize='12px' color='black' />
    } else {
        return <IconFactory icon='down-solid' fontSize='12px' color='black' />
    }
}

const SelectFilter = (props) => {
    const { value, name, onChange, collection, onClick, className, placeholder } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const theme = useTheme()

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            PaperProps: {
                style: {
                    maxHeight: 350,
                    boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
                    border: `2px solid ${theme.palette.fg.black}`,
                    backgroundColor: theme.palette.bg.white,
                    borderRadius: 16
                }
            }
        })
    }

    return (
        <FormControl className={classes.formControl} required onClick={() => setOpen(!open)}>
            <Select
                className={`${classes.select} ${className}`}
                value={value}
                name={name}
                inputProps={{ MenuProps: selectPaperStyle() }}
                open={open}
                onClick={onClick}
                disableUnderline
                onChange={onChange}
                placeholder={placeholder}
                IconComponent={() => <OpenIcon open={open}/>}
                displayEmpty
            >
                <MenuItem value=''>
                    <em>{placeholder}</em>
                </MenuItem>
                {collection.map((item, itemKey) => {
                    return (
                        <MenuItem className={classes.menuItem} key={itemKey.toString()} value={item.value}>
                            <IconFactory icon='ads' fontSize='20px' color='' />
                            <span>{item.name}</span>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SelectFilter
