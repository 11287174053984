import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    cardContainer: {
        width: '100%',
        maxWidth: '341px',
        border: `1px solid ${theme.palette.fg.silver}`,
        borderRadius: '20px',
        padding: '28px 28px 31px'
    },
    title: {
        paddingBottom: '24px',
        color: theme.palette.fg.black,
        fontSize: '24px',
        lineHeight: '28px'
    },
    jobInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px'
    },
    applyButtonBox: {
        paddingTop: '28px'
    },
    applyButtonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px'
    },
    applyButton: {
        width: '100%'
    }
}))
