/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import Map from 'wrappers/MapProvider/components/Map/Map'
import CardContainer from 'containers/CardContainer/CardContainer'
import ListContainer from 'containers/ListContainer/ListContainer'
import RecentArticles from 'containers/RecentArticles/RecentArticles'
import AdBanner from 'components/AdBanner/AdBanner'
import NewOfferButton from 'components/NewOfferButton/NewOfferButton'
import ArticleBanner from '../ArticleBanner/ArticleBanner'
import SEOBlock from '../SEOBlock/SEOBlock'
import {
    JOB_INDEX_FILTER_BUTTON,
    JOB_INDEX_MOBILE_STICKY_BUTTON,
    JOB_INDEX_ALERT_AD_BUTTON,
    JOB_INDEX_SEE_MORE_BUTTON
} from 'assets/AlertsHelper'
import { JOB_TYPES_LIST } from 'assets/ItemHelper'
import css from './itemindex.scss'
import css_responsive from './itemindex_responsive.scss'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'
import { FormattedMessage } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import CTAAlert from 'components/CTAAlert/CTAAlert'

const ItemIndex = (props) => {
    const listRef = useRef(null)
    const cardRef = useRef(null)

    const createListContainers = (itemList, pageToShow) => {
        return itemList.map((items, i) => (
            <Box
                key={`listcontainer_${i}`}
                className={`page-list-to-${i < pageToShow ? 'show' : 'show'}`}
            >
                <ListContainer
                    showAds
                    ads={props.ads}
                    type={props.type}
                    userType={props.userType}
                    displayShow={props.displayShow}
                    listCards={items}
                    mapProviderProps={props.mapProviderProps}
                    index={i}
                />
                {props.type !== 'company' && (
                    <Box id={`listAds-mobile-${i}`} mb={4} mt={3}>
                        {listAds(i)}
                    </Box>
                )}
            </Box>
        ))
    }

    const createCardContainers = (itemList, pageToShow) => {
        return itemList.map((items, i) => (
            <Box
                className={`page-card-to-${i < pageToShow ? 'show' : 'show'}`}
                key={`cardcontainer_${i}`}
                ref={i === itemList.length - 1 ? props.jumpingRef : null}
                id={`cardContainer_${i}`}
            >
                {i !== 0 && (
                    <Box id={`listAds-desktop-${i}`} mb='21px'>
                        {listAds(i)}
                    </Box>
                )}
                <CardContainer
                    key={`cardcontainer_${i}`}
                    type={props.type}
                    displayShow={props.displayShow}
                    cards={items}
                    userAddress={props.userAddress}
                    userType={props.userType}
                    pageName={props.pageName}
                    mapProviderProps={props.mapProviderProps}
                />
            </Box>
        ))
    }

    const halfPageAds = () => {
        const locale = RouteMap.GetLocaleFromUrl()
        const localizedAds = props.ads.filter((ad) => ad.langage === locale)
        const places = []

        localizedAds.forEach((ad) => {
            ad.places.forEach((place) => {
                if (place.includes('Index_Half page')) {
                    places.push(place)
                }
            })
        })

        if (places.length === 0 && props.type !== 'article') {
            return (
                <Box pl={2}>
                    <RecentArticles key='recent-articles-kot' category={props.type} />
                </Box>
            )
        }

        places.sort()

        return places.map((place, index) => (
            <React.Fragment key={`ad-fragment-${index}`}>
                <AdBanner
                    key={`ad-banner-${index}`}
                    ads={localizedAds}
                    place={place}
                    device='desktop'
                    width={300}
                    noMarginBottom
                />
                {index === 0 && (
                    <Box style={{ width: '100%' }}>
                        <RecentArticles key={`recent-articles-${index}`} category={props.type} />
                    </Box>
                )}
            </React.Fragment>
        ))
    }

    const listAdForArticleTag = () => {
        switch (props.selectedArticleTagValue) {
        case 'first_job':
        case 'internship':
        default:
            return 'Blog_Index_Leaderboard_'
        }
    }

    const listAdForJobType = () => {
        switch (props.type) {
        case 'first_job':
            return 'Firstjobs_Index_Leaderboard_'
        case 'internship':
            return 'Internships_Index_Leaderboard_'
        case 'student_job':
            return 'Studentjobs_Index_Leaderboard_'
        case 'kot':
            return 'Kots_Index_Leaderboard_'
        default:
            return ''
        }
    }

    const listAds = (index) => {
        const place = listAdForArticleTag() + index
        const isIndexEven = index % 2 === 0
        const isJobType = JOB_TYPES_LIST.includes(props.type)

        if (
            props.type !== 'article' &&
            ['Mobile', 'Leaderboard'].includes(place.split('_')[2])
        ) {
            return isJobType && !isIndexEven ? (
                <ArticleBanner offset={index / 2} category={props.type} />
            ) : (
                isJobType &&
                (props.userType === 'student' || !props.user) && (
                    <CTAAlert
                        filters={props.filters}
                        type={props.type}
                        user={props.user}
                        componentType='banner'
                        origin={JOB_INDEX_ALERT_AD_BUTTON}
                    />
                )
            )
        }

        index = index + 2
        return props.type === 'article' ? (
            <AdBanner
                ads={props.ads}
                key={listAdForArticleTag()}
                place={place}
                device='desktop'
                width={728}
            />
        ) : (
            <AdBanner
                ads={props.ads}
                key={listAdForJobType()}
                place={listAdForJobType() + index}
                device='desktop'
                width={728}
            />
        )
    }

    const showMoreClick = () => {
        props.filterItems(false)
    }

    const chunckedItems = []
    for (let i = 0, j = props.items.length; i < j; i += 9) {
        chunckedItems.push(props.items.slice(i, i + 9))
    }

    const pageToShow = window.innerWidth <= 1140 ? 2 : 3

    const listContainers = createListContainers(chunckedItems, pageToShow)
    const cardContainer = createCardContainers(chunckedItems, pageToShow)

    return (
        <Box className='item-index-container'>
            <Box className={`cards-and-list ${props.type}-type`}>
                {props.loading && (
                    <div className='loading-cirular'>
                        <CircularProgress
                            color='primary'
                            style={{ width: '80px', height: '80px' }}
                        />
                    </div>
                )}
                {!props.loading && props.items.length > 0 && (
                    <>
                        {Array.isArray(props.ads) && props.ads.length > 0 && (
                            <>
                                <AdBanner
                                    ads={props.ads}
                                    place={listAdForJobType() + 'Leaderboard_1'}
                                    device='desktop'
                                    width={728}
                                />
                                <AdBanner
                                    ads={props.ads}
                                    place={listAdForJobType() + 'Mobile_1'}
                                    device='mobile'
                                    width={320}
                                />
                            </>
                        )}
                        <Hidden smDown>
                            <Box className='buttonsMobileContainer'>
                                <NewOfferButton userType={props.userType} type={props.type} />
                                <CTAAlert
                                    filters={props.filters}
                                    type={props.type}
                                    user={props.user}
                                    componentType='default'
                                    origin={JOB_INDEX_FILTER_BUTTON}
                                    key='buttonsMobileContainer-cta-alert'
                                />
                            </Box>
                        </Hidden>
                        {listContainers && (
                            <Box className='item-index-list closed' ref={listRef}>
                                {listContainers}
                                <CTAAlert
                                    componentType='showMoreButton'
                                    filters={props.filters}
                                    type={props.type}
                                    loadingMore={props.loadingMore}
                                    loadMoreStatus={props.loadMoreStatus}
                                    user={props.user}
                                    showMoreAction={showMoreClick}
                                    origin={JOB_INDEX_SEE_MORE_BUTTON}
                                    key='item-index-list-cta-alert'
                                />
                            </Box>
                        )}
                        <Box className='item-index-cards' ref={cardRef}>
                            {cardContainer}
                            <CTAAlert
                                componentType='showMoreButton'
                                filters={props.filters}
                                type={props.type}
                                loadingMore={props.loadingMore}
                                loadMoreStatus={props.loadMoreStatus}
                                user={props.user}
                                showMoreAction={showMoreClick}
                                origin={JOB_INDEX_SEE_MORE_BUTTON}
                                key='item-index-list-cta-alert'
                            />
                        </Box>
                    </>
                )}
                {props.loadingMore && (
                    <Box display='flex' justifyContent='center' fullWidth my={4}>
                        <CircularProgress color='primary' style={{ width: '80px', height: '80px' }} />
                    </Box>
                )}
                {!props.loading && props.items.length === 0 && (
                    <Box mt={4} mb={8}>
                        <Typography variant='body2'>
                            <FormattedMessage id='item_index.no_found_offer' />
                        </Typography>
                    </Box>
                )}
                <SEOBlock
                    type={props.type}
                    selectedLocationTag={props.selectedLocationTag}
                />
            </Box>
            <Box className='item-index-right-panel'>
                {((props.type === 'kot' && props.isCheckMap) || props.type !== 'kot') && (
                    <Box className='half-page-ads'>
                        {['first_job', 'internship', 'student_job', 'kot', 'article'].includes(props.type) && (
                            <>
                                <Box className='buttons-container'>
                                    <NewOfferButton userType={props.userType} type={props.type} />
                                    <CTAAlert
                                        filters={props.filters}
                                        type={props.type}
                                        user={props.user}
                                        componentType='default'
                                        origin={JOB_INDEX_FILTER_BUTTON}
                                        key='buttons-container-cta-alert'
                                    />
                                </Box>
                                {halfPageAds()}
                            </>
                        )}
                    </Box>
                )}
                {props.itemsGeocoding && props.type === 'kot' && !props.isCheckMap && (
                    <Box className={'map-container map-shown'}>
                        <Map
                            type={props.type}
                            itemsGeocoding={props.itemsGeocoding}
                            ref={props.mapRef}
                            mapProviderProps={props.mapProviderProps}
                        />
                    </Box>
                )}
            </Box>
            {JOB_TYPES_LIST.includes(props.type) &&
                (props.userType === 'student' || !props.user) && (
                <Hidden smUp>
                    <CTAAlert
                        filters={props.filters}
                        type={props.type}
                        user={props.user}
                        componentType='stickyButton'
                        origin={JOB_INDEX_MOBILE_STICKY_BUTTON}
                        key='sticky-button-cta-alert'
                    />
                </Hidden>
            )}
        </Box>
    )
}

export default ItemIndex
