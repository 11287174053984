import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    CTAContainer: {
        borderRadius: '12px',
        backgroundColor: 'var(--red)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '30px'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '20px',
            padding: '16px'
        }
    },
    ctaButton: {
        padding: '10px 34px',
        borderRadius: '8px',
        backgroundColor: 'white !important',
        color: 'black',
        height: '40px',
        boxShadow: 'none',
        '& p': {
            fontSize: '12px !important',
            fontFamily: 'GreedStandard-Medium, sans-serif !important'
        },
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#fafafa !important'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    ctaLink: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    CTAContainerTitle: {
        color: 'white',
        fontSize: '16px',
        fontFamily: 'GintoNord-Bold, sans-serif !important'
    }
}))
