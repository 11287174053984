import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    resetButton: {
        width: '49px',
        minWidth: '49px',
        height: '49px',
        padding: '0px',
        backgroundColor: theme.palette.bg['black'],
        borderRadius: '80px',
        border: `1px solid ${theme.palette.fg['black']}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.palette.bg['black'],
        },
    },

    resetIcon: {
        width: "21px",
        height: "22px"
    }
}))
