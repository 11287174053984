import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    filtersViewContainer: {
        width: '100%',
    },

    inner: {
        width: '100%',
        backgroundColor: theme.palette.bg['purple'],
        padding: '47px 25px 36px',
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            paddingTop: '53px',
            paddingBottom: '60px'
        },
        [theme.breakpoints.up(1228)]: {
            paddingTop: '67px',
        },
        [theme.breakpoints.up(1366)]: {
            padding: '45px 50px 55px',
        },
    },

    titleContainer: {
        paddingBottom: '33px',
        color: theme.palette.fg['white'],
        fontSize: '40px',
        lineHeight: '50px',
        textAlign: 'center',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: '51px',
            lineHeight: '53px',
        },
        [theme.breakpoints.up(750)]: {
            fontSize: '63px',
            lineHeight: '65px',
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: '25px',
        },
        [theme.breakpoints.up(1366)]: {
            paddingBottom: '30px',
        },

        '& .GreedCondensedHeavy': {
            fontFamily: 'GreedCondensed-Heavy',
        },

        '& .GreedCondensedRegular': {
            fontFamily: 'GreedCondensed-Regular',
        },

        '& .GreedWideHeavy': {
            fontFamily: 'GreedWide-Heavy',
        },

        '& .GreedWideSemiBold': {
            fontFamily: 'GreedWide-SemiBold',
        },
    },

}))
