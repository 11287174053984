import React, { useEffect } from 'react'
import postscribe from 'postscribe'
import AnalyticsHelper from 'assets/AnalyticsHelper'

import css from './adbanner.scss'
import css_mobile from './adbanner_mobile.scss'
import Box from '@material-ui/core/Box'

import { Image, Transformation } from 'cloudinary-react'
import RouteMap from 'assets/RouteMap'
import AdsAPI from 'api/AdsAPI'

const AdBanner = (props) => {
    const { device, ads, place, noMarginBottom, width, style, responsiveBanner } = props
    const renderIframe = (ad) => {
        return <div dangerouslySetInnerHTML={{ __html: ad.iframe_tag }}></div>
    }

    const renderJavascript = (ad) => {
        /* const javascriptContent = ad.javascript_tag.replace(/<script>/, '').replace('</script>', '')
        const script = document.createElement('script')
        script.innerHTML = javascriptContent
        document.body.appendChild(script)
        return null */
    }

    useEffect(() => {
        const filteredAds = getFilteredAds()
        if (device === (window.innerWidth < 576 ? 'mobile' : 'desktop') && filteredAds.length !== 0) {
            const ad = filteredAds[Math.floor(Math.random() * filteredAds.length)]
            sendAdBannerEvent(ad, 'AdBanner Impression')
            increaseAdShowCounter(ad)
        }
        if (document.getElementById('dcmads') != null) {
            postscribe('#dcmads', "<script src=\'https://www.googletagservices.com/dcm/dcmads.js\'></script>")
        }
    }, [])

    const increaseAdShowCounter = (ad) => {
        AdsAPI.IncreaseAdShowcounter({
            ad_id: ad.id
        })
    }

    const sendAdBannerEvent = (ad, event) => {
        const label = ad.campaign_name + ' - ' + ad.langage + '-' + place
        AnalyticsHelper.sendGTMEvent(
            event,
            AnalyticsHelper.constructBannerAnalyticsAttributes(label)
        )
    }

    const getFilteredAds = () => {
        return ads
            .filter(ad => ad.places.includes(place))
            .filter(ad => locale === ad.langage)
    }

    const locale = RouteMap.GetLocaleFromUrl()
    const filteredAds = getFilteredAds()

    if (device !== (window.innerWidth < 576 ? 'mobile' : 'desktop')) return null
    if (filteredAds.length === 0) return null
    const ad = filteredAds[Math.floor(Math.random() * filteredAds.length)]

    let newWidth = width
    if (place.includes('Leaderboard') && ad.format === 'billboard') {
        newWidth = 970
    }

    const marginBottom = noMarginBottom ? 0 : '20px'

    return (
        <Box className={'ad-bannerdse ' + (window.innerWidth < 576 ? 'mobile' : 'desktop')} style={style} >
            <div className="ad-banner-content" >
                <a href={ad.url} target="_blank" rel='noreferrer' onClick={() => sendAdBannerEvent(ad, 'AdBanner Click')}>
                    {ad.isMultiHtml &&
                        <div style={{ marginBottom: marginBottom }}>
                            { renderIframe(ad) }
                            { renderJavascript(ad) }
                        </div>
                    }
                    {ad.isHtml &&
                        <div style={{ marginBottom: marginBottom }} dangerouslySetInnerHTML={{ __html: ad.html }}/>
                    }
                    {ad.isImage &&
                        <div style={{ marginBottom: marginBottom }} >
                            <Image
                                cloudName="studentbe"
                                publicId={ad.cloudinary_key}
                                alt={ad.cloudinary_key}
                                width='100%'
                            >
                                <Transformation width="auto" flags="progressive" fetchFormat="auto" quality="auto:good" crop="scale" dpr="auto" />
                            </Image>
                        </div>
                    }
                </a>
            </div>
        </Box>
    )
}

export default AdBanner
