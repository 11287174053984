import React from 'react'

import { useStyles } from './FirstJobFiltersModalView_style'
import { Box } from '@material-ui/core'

import TextFilter from 'components/Searchbar/shared/TextFilter/TextFilter'
import BooleanFilter from 'components/Searchbar/shared/BooleanFilter/BooleanFilter'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import CheckboxList from 'components/Searchbar/shared/CheckboxList/CheckboxList'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import CollectionHandler from 'assets/CollectionHandler.js'
import { FiltersModalWrapper, SectionTitle, SectionDescription } from 'modals/FilterModals/FiltersModalWrapper'
import MultipleSelectFilter from 'components/Searchbar/shared/MultipleSelectFilter/MultipleSelectFilter'

const FirstJobFiltersModalView = (props) => {
    const classes = useStyles()
    const { reset, showModal, closeModal, offerCount, debouncedFilterChange, handleFilterChange, filters, inputField, setInputField, setTimeoutInput, handleInputSearchCity, searchCities, citiesInput, setSearchCities, setCitiesInput, setFilters, setFiltersPerType } = props
    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')

    return (
        <FiltersModalWrapper
            type='first_job'
            titleId='job_filters.more_filters'
            buttonId='job_results.popup.save_button'
            showModal={showModal}
            closeModal={closeModal}
            offerCount={offerCount}
            reset={reset}
        >
            {/* Search a job section */}
            <Box className={classes.searchJobectionContainer}>
                <SectionTitle id='job_filters.text_filter.title' />
                <SectionDescription id='job_filters.text_filter.description' />
                <Box className={classes.searchJobField}>
                    <TextFilter
                        handleFilterChange={handleFilterChange}
                        debouncedFilterChange={debouncedFilterChange}
                        filters={filters}
                        setFilters={setFilters}
                        filterTextId='search_bar.search.job'
                        modal
                    />
                </Box>
            </Box>
            {/* Field of study section */}
            <Box className={classes.fieldOfStudySectionContainer}>
                <SectionTitle id='job_filters.study_fields.title' />
                <SectionDescription id='job_filters.study_fields.description' />
                <Box className={classes.checkboxListContainer}>
                    <CheckboxList
                        type='studyDomain'
                        collection={studyDomainCollection}
                        selectedItems={filters.studyDomain}
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'studyDomain') }}
                        setFilters={setFilters}
                        width={400}
                    />
                </Box>
            </Box>
            {/* Location section */}
            <Box className={classes.locationSectionContainer}>
                <SectionTitle id='job_filters.location.title' />
                <Box className={classes.locationField}>
                    <CityFilter
                        handleInputSearchCity={handleInputSearchCity}
                        searchCities={searchCities}
                        citiesInput={citiesInput}
                        setSearchCities={setSearchCities}
                        setCitiesInput={setCitiesInput}
                        setFilters={setFilters}
                        filters={filters}
                        width={500}
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </Box>
            {/* Sector section */}
            <Box className={classes.sectorSectionContainer}>
                <SectionTitle id='job_filters.sector.title' />
                <SectionDescription id='job_filters.sector.description' />
                <Box className={classes.sectorField}>
                    <MultipleSelectFilter
                        type='sector'
                        collection={sectorCollection}
                        filters={filters}
                        setFilters={setFilters}
                        width={500}
                        filterTextId='search_bar.sector'
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </Box>
            {/* Company size section */}
            <Box className={classes.companySizeSectionContainer}>
                <SectionTitle id='job_filters.type_of_company.title' />
                <SectionDescription id='job_filters.type_of_company.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <TagMultiSelect
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'companySize') }}
                        selectedItems={filters.companySize}
                        collection={companySizeCollection}
                        width={600}
                    />
                </Box>
            </Box>
            {/* Traineeships section */}
            <Box className={classes.traineeshipsSection}>
                <SectionTitle id='job_filters.traineeships.title' />
                <SectionDescription id='job_filters.traineeships.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <BooleanFilter
                        type='traineeship'
                        filters={filters}
                        setFilters={setFilters}
                        filterTextId='filters.traineeship'
                    />
                </Box>
            </Box>
        </FiltersModalWrapper>
    )
}

export default FirstJobFiltersModalView
