import React from 'react'
import ResponsiveVideo from 'components/ResponsiveVideo/ResponsiveVideo'

import { useStyles } from './JobShowMedia_style'
import { Box, Divider } from '@material-ui/core'

import { Image, Transformation } from 'cloudinary-react'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'

const JobShowMedia = (props) => {
    const { job } = props
    const classes = useStyles()
    const shouldRenderVideo = job?.show_add_video && job?.video_url

    if (!shouldRenderVideo && !job?.job_show_cloudinary_key) return null

    if (shouldRenderVideo) {
        return <>
            <ResponsiveVideo videoUrl={job.video_url} />
            <Divider className={classes.divider} />
        </>
    }

    return (
        <>
            <Box className={classes.jobImageContainer}>
                <Image
                    cloudName="studentbe"
                    publicId={job.job_show_cloudinary_key}
                    alt={job.job_show_cloudinary_key}
                    className={classes.image}
                >
                    <Transformation flags="progressive" fetchFormat="auto" quality="auto:best" width={MEDIUM_SIZE} crop="fill" />
                </Image>
            </Box>
            <Divider className={classes.divider} />
        </>
    )
}

export default JobShowMedia