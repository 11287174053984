import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    RecommendedJobsContainer: {
        width: '100%',
        padding: '89px 28px',
        backgroundColor: theme.palette.bg['blue'],
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.up('md')]: {
            paddingTop: '90px',
            paddingBottom: '66px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },

    innerBox: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '887px'
        },
    },

    title: {
        paddingBottom: '42px',
        textAlign: 'center',
        color: theme.palette.fg['black'],
        fontFamily: 'GreedWide-Heavy',
        fontSize: '32px',
        lineHeight: '40px',
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '36px',
            fontSize: '40px',
            lineHeight: '50px'
        },
    },

    cardsContainer: {
        paddingBottom: '38px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '57px',
            rowGap: '25px'
        },
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))
