import React from 'react'
import { Image } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MobileBurgerMenuContainer_style'
import {
    AppBar, Dialog, IconButton,
    Toolbar, Box, Typography, useTheme
} from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import MyMessagesIcon from '../../MyMessagesIcon/MyMessagesIcon'
import Notifications from '../../Notifications/Notifications'
import ProfileHelper from 'assets/ProfileHelper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import Button from 'shared/components/Button/Button.jsx'

const MobileBurgerMenuContainer = (props) => {
    const classes = useStyles()
    const {
        children, open, closeModal,
        jobTypeSelection, setJobTypeSelection,
        user, altLangUrls, paramsForUrlTranslation
    } = props
    const theme = useTheme()

    const closeModalAndResetState = () => {
        closeModal()
        setJobTypeSelection(null)
    }

    const renderUserActionsTypo = () => {
        return user
            ? ProfileHelper.GetProfileName(user)
            : <FormattedMessage id='page_footer_navbar_data.navbar' />
    }

    const renderUserRedirection = () => {
        return user
            ? ProfileHelper.GetProfileUrl(user.type)
            : 'login'
    }

    const renderHomeHeader = () => {
        return (<Box className={classes.homeHeader}>
            <Image
                className={classes.logo}
                cloudName='studentbe'
                publicId='student-be-logos/Student_2024_Logo_Main.png'
                alt='Student.be'
            />
            <IconButton edge='start' onClick={closeModal} className={classes.closeButton} disableRipple>
                <IconFactory icon='close' className={classes.closeIcon} />
            </IconButton>
        </Box>)
    }

    const renderJobSelectionHeader = () => {
        return (
            <Box className={classes.jobSelectionHeader}>
                <Box className={classes.jobSelectionHeaderLeftContainer}>
                    <IconButton
                        edge='start'
                        onClick={() => setJobTypeSelection(null)}
                        className={classes.leftArrowButton}
                        disableRipple
                    >
                        <IconFactory icon='left-regular' className={classes.leftArrowIcon} />
                    </IconButton>
                    <SVGFactory name={jobTypeSelection?.iconName} width='14px' height='17px' />
                    <Typography component='div' className={classes.jobSelectionHeaderTitle}>
                        {jobTypeSelection?.title}
                    </Typography>
                </Box>

                <IconButton edge='start' onClick={closeModalAndResetState} className={classes.closeButton} disableRipple>
                    <IconFactory icon='close' className={classes.closeIcon} />
                </IconButton>
            </Box>
        )
    }

    return (
        <Dialog
            PaperProps={{ className: classes.dialogContainer }}
            fullScreen
            open={open}
            onClose={closeModalAndResetState}
        >
            <AppBar position='static' color='white' elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {jobTypeSelection
                        ? renderJobSelectionHeader()
                        : renderHomeHeader()
                    }
                </Toolbar>
            </AppBar>
            <Box className={classes.contentMenuContainer}>
                {children}
            </Box>
            <Box className={classes.footerMenuContainer}>
                <RouteMapLink page={renderUserRedirection()} className={classes.link}>
                    <Button className={classes.redirectionButton}>
                        {renderUserActionsTypo()}
                    </Button>
                </RouteMapLink>
                {user && <Box className={classes.userActions}>
                    <Notifications user={user} iconColor={theme.palette.fg['black']} />
                    <MyMessagesIcon user={user} iconColor={theme.palette.fg['black']} />
                </Box>}
            </Box>
        </Dialog>
    )
}

export default MobileBurgerMenuContainer
