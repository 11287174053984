import React, { useState } from 'react'
import { Image } from 'cloudinary-react'

import { useStyles } from './MobileAppBar_style'
import { useStyles as useDarkModeStyles } from './MobileAppBarDarkMode_style'
import {
    AppBar, Grid, Hidden,
    IconButton, Toolbar
} from '@material-ui/core'

import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import MobileBurgerMenu from '../MobileBurgerMenu/MobileBurgerMenu'
import ProfileHelper from 'assets/ProfileHelper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import LogoHelper from 'assets/LogoHelper'

const MobileAppBar = (props) => {
    const { altLangUrls, user, paramsForUrlTranslation, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()
    const [open, setOpen] = useState(false)

    const openModal = () => {
        setOpen(true)
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <Hidden mdUp>
            <MobileBurgerMenu
                open={open}
                closeModal={closeModal}
                user={user}
                altLangUrls={altLangUrls}
                paramsForUrlTranslation={paramsForUrlTranslation}
            />
            <AppBar position='static' color='white' elevation={0} className={classes.appBar}>
                <Image
                    className={classes.logo}
                    cloudName='studentbe'
                    publicId='student-be-logos/Student_2024_Logo_Main.png'
                    alt='Student.be'
                />
                <IconButton
                    edge='start'
                    onClick={openModal}
                    className={classes.button}
                    disableRipple
                >
                    <IconFactory icon='bars' className={classes.burgerIcon} />
                </IconButton>
            </AppBar>
        </Hidden>
    )
}

export default MobileAppBar
