import React, { useState } from 'react'
import { Box, Divider } from '@material-ui/core'
import ProfileIdentityBox from './components/ProfileIdentityBox/ProfileIdentityBox'
import JobSearchStatusBox from './components/JobSearchStatusBox/JobSearchStatusBox'
import GoToProfileBox from './components/GoToProfileBox/GoToProfileBox'
import NotificationBox from './components/NotificationBox/NotificationBox'
import StudyExperienceBox from './components/StudyExperienceBox/StudyExperienceBox'
import { useStyles } from './ProfilePassport_style'

const ProfilePassport = (props) => {
    const { user, openProfileEditFormOnStep, context, profileId, pictureId, children } = props
    const classes = useStyles()
    const [imageAvatar, setImageAvatar] = useState([])

    return (
        <Box className={classes.paper}>
            <Box className={classes.sectionBox}>
                <ProfileIdentityBox
                    user={user}
                    profileId={profileId}
                    pictureId={pictureId}
                    imageAvatar={imageAvatar}
                    setImageAvatar={setImageAvatar}
                />
            </Box>
            <Divider />
            <Box className={classes.sectionBox}>
                <JobSearchStatusBox user={user} />
            </Box>
            <Divider />
            {context === 'personal_dashboard' && <>
                {user.current_experience && <>
                    <Box className={classes.sectionBoxWidth}>
                        <StudyExperienceBox user={user} openProfileEditFormOnStep={openProfileEditFormOnStep} />
                    </Box>
                    <Divider />
                </>}
                <Box className={classes.sectionBoxWidth}>
                    <NotificationBox user={user} />
                </Box>
                <Divider />
                <Box className={classes.sectionBox}>
                    <GoToProfileBox />
                </Box>
            </>}
            {context === 'student_profile' && children}
        </Box>
    )
}
export default ProfilePassport
