import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    cityFilterViewContainer: {
        width: '100%'
    },

    cityFilterViewFormControl: {
        width: '100%'
    },

    cityFilterViewTrigger: {
        width: '100%',
        height: '49px',
        padding: '0px',
        border: `2px solid ${theme.palette.fg.black}`,
        backgroundColor: theme.palette.bg.white,
        borderRadius: '80px',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.bg.white,
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`
        },
        '&:hover $triggerPlaceholderText, &:hover $triggerArrowIcon': {
            color: theme.palette.fg.purple
        }
    },

    cityFilterViewTriggerClicked: {
        boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`
    },

    select: {
        padding: '0 !important',
        width: '100%',
        height: '100%'
    },

    selectInput: {
        width: '100%'
    },

    // Placeholder
    triggerPlaceholderContainer: {
        height: '49px',
        padding: '0px 20px 0px 18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '5px'
    },

    triggerPlaceholderText: {
        textTransform: 'none',
        color: theme.palette.fg.greyDark,
        fontFamily: 'GreedStandard-Regular',
        fontSize: '16px',
        lineHeight: '18px'
    },

    triggerArrowIcon: {
        color: theme.palette.fg.black60Opacity
    },

    tagAvatar: {
        fontFamily: 'GreedStandard-Bold',
        fontSize: 14,
        width: 20,
        height: 18,
        color: theme.palette.fg.white,
        backgroundColor: theme.palette.bg.black,
        marginLeft: 8
    },

    searchInputContainer: {
        width: '100%',
        padding: '12px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '8px',
        borderBottom: `1px solid ${theme.palette.fg.beigeLight}`
    },

    selectedItemsListContainer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 45,
        padding: '0px 16px'
    },

    textInputContainer: {
        width: '100%',
        gap: 8,
        height: '49px',
        padding: '0px 14px',
        border: `2px solid ${theme.palette.fg.black}`,
        backgroundColor: theme.palette.bg.white,
        borderRadius: '80px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '5'
    },
    textInputSearchContainer: {
        gap: 8,
        height: '49px',
        padding: '0px 14px',
        border: `2px solid ${theme.palette.fg.black}`,
        backgroundColor: theme.palette.bg.white,
        borderRadius: '80px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '5',
        '&:hover': {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
            border: `2px solid ${theme.palette.fg.black}`,
            backgroundColor: theme.palette.bg.white,
            color: 'var(--foreground-purple) !important',
            '& svg': {
                color: 'var(--foreground-purple) !important'
            }
        },
    },

    searchIcon: {
        width: '21px',
        height: '21px'
    },

    textInput: {
        flex: 1,
        height: '100%',
        padding: '0px',
        fontSize: '16px',
        lineHeight: '18px',
        color: theme.palette.fg.greyDark,
        '&::placeholder': {
            fontSize: '16px',
            lineHeight: '18px',
            color: theme.palette.fg.greyDark,
            opacity: 1
        }
    },
    textInputSearch: {
        flex: 1,
        height: '100%',
        padding: '0px',
        fontFamily: 'GreedStandard-Medium',
        fontSize: '16px',
        lineHeight: '18px',
        color: theme.palette.fg.greyDark,
        '&::placeholder': {
            fontFamily: 'GreedStandard-Medium',
            fontSize: '16px',
            lineHeight: '18px',
            color: theme.palette.fg.greyDark,
            opacity: 1
        }
    },

    closeIconButton: {
        height: 'fit-content',
        padding: '0px',
        '& svg': {
            width: 18
        }
    },

    menuItem: {
        padding: '10px 24px',
        '&:hover': {
            backgroundColor: theme.palette.bg.white,
            color: theme.palette.fg.purple
        }
    },

    filterTagList: {
        marginTop: 10
    },
    cityFilterDropdown: {
        zIndex: 2000,
        marginTop: '10px',
        maxWidth: 400,
        boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['white'],
        borderRadius: '30px'
    }
}))
