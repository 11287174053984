import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 'unset',
        padding: '0px',
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },

    badge: {
        '& .MuiBadge-anchorOriginTopRightCircle': {
            padding: '2px',
            backgroundColor: theme.palette.bg['black'],
            borderRadius: '50%',
            color: theme.palette.fg['white'],
            fontFamily: 'GreedStandard-Medium',
            fontSize: '10px',
            lineHeight: '11px'
        }
    },

    dropdownTitle: {
        backgroundColor: 'var(--beige)'
    },

    menuList: {
        padding: '0',
        maxHeight: '90vh',
        overflow: 'auto'
    },

    notificationIcon: {
        fontSize: '24px !important'
    }
}))
