import React from 'react'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobCard_style'
import { Box, Typography, Hidden } from '@material-ui/core'

import { SMALL_SIZE, SMALLER_SIZE } from 'assets/CloudinaryURLHelper'
import FavoriteAction from './components/FavoriteAction/FavoriteAction'
import JobCardChips from './components/JobCardChips/JobCardChips'
import JobCardWrapper from './JobCardWrapper'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import URLHandler from 'assets/URLHandler'

const JobCardV2 = (props) => {
    const { job, onCardClick } = props

    const classes = useStyles()
    const jobCardYoutubeId = job.job_video_youtube_id
    const jobCardImageId = job.job_image_cloudinary_key || `placeholders/${job.sector || 'other_industries'}`
    const youtubeImageUrl = URLHandler.youtubeImageUrlBuilder(jobCardYoutubeId)

    return (
        <JobCardWrapper
            onCardClick={onCardClick}
            jobType={job.type}
            jobUrl={job.url}
            jobId={job.id}
        >
            <Box className={classes.containerCard}>
                <Hidden xsDown>
                    <Box className={classes.containerCover}>
                        {jobCardYoutubeId ? (
                            <img
                                className={classes.cardMediaV2}
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%), url(${youtubeImageUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: 'black'
                                }}
                            />
                        ) : (
                            <Image
                                cloudName='studentbe'
                                className={classes.cardMediaV2}
                                publicId={jobCardImageId}
                                alt='sector image'
                            >
                                <Transformation
                                    flags='progressive'
                                    fetchFormat='auto'
                                    height={SMALL_SIZE}
                                    quality='auto:good'
                                    crop='fill'
                                />
                                <Transformation effect='gradient_fade' y='-0.5' />
                                <Transformation background='#222222' opacity='90' />
                                <Transformation defaultImage='placeholders:other_industries.png' />
                            </Image>
                        )}
                        {job.company_logo_cloudinary_key &&
                            <Box className={classes.containerLogoCompany}>

                                <Image
                                    className={classes.companyMedia}
                                    cloudName='studentbe'
                                    publicId={job.company_logo_cloudinary_key}
                                    alt={`Company logo ${job.company_name}`}
                                >
                                    <Transformation
                                        flags='progressive'
                                        fetchFormat='auto'
                                        width={SMALLER_SIZE}
                                        quality='auto:good'
                                        crop='fill'
                                        gravity='center'
                                    />
                                    <Transformation radius='20' />
                                </Image>

                            </Box>}

                    </Box>
                </Hidden>

                <Hidden xsDown>
                    <Box className={classes.containerBody}>
                        <Box className={classes.containerBodyLeft}>
                            <Box className={classes.containerTitles}>
                                <Typography
                                    variant='h2'
                                    component='p'
                                    className={classes.companyName}
                                >
                                    {job?.company?.name || ''}
                                </Typography>
                                <Typography
                                    variant='h3'
                                    component='p'
                                    className={classes.title}
                                >
                                    {job.title || ''}
                                </Typography>
                            </Box>
                            <Box className={classes.containerTags}>
                                <Box className={classes.containerLocation}>
                                    <SVGFactory
                                        skipSvgWrapper
                                        {...props}
                                        name='location'
                                    />
                                    <Typography
                                        component='p'
                                        className={classes.location}
                                    >
                                        {job.city || ''}
                                    </Typography>
                                </Box>
                                {job?.job_tags &&
                                    Array.isArray(job.job_tags) && <JobCardChips job={job} />
                                }
                            </Box>
                        </Box>
                        <Box className={classes.containerBodyRight}>
                            <Box>
                                {job.is_impact &&
                                    <Box className={classes.containerJobType}>
                                        <SVGFactory name='earth' width="25" height="25" />
                                    </Box>
                                }
                            </Box>
                            <FavoriteAction
                                userType={props.userType}
                                color='var(--black)'
                                item={job}
                                onItemCard
                            />
                        </Box>
                    </Box>
                </Hidden>

                <Hidden smUp>
                    <Box className={classes.containerBodyMobile}>
                        <Box className={classes.containerBodyHeaderMobile}>
                            <Box className={classes.containerBodyHeaderRightMobile}>
                                {job.company_logo_cloudinary_key && <Box className={classes.containerLogoCompanyMobile}>
                                    <Image
                                        className={classes.companyMedia}
                                        cloudName='studentbe'
                                        publicId={job.company_logo_cloudinary_key}
                                        alt={`Company logo ${job.company_name}`}
                                    >
                                        <Transformation
                                            flags='progressive'
                                            fetchFormat='auto'
                                            width={SMALLER_SIZE}
                                            quality='auto:good'
                                            crop='fill'
                                            gravity='center'
                                        />
                                        <Transformation radius='20' />
                                    </Image>

                                </Box>}
                                <Typography
                                    variant='h3'
                                    component='p'
                                    className={classes.companyNameMobile}
                                >
                                    {job?.company?.name || ''}
                                </Typography>
                            </Box>
                            <Box>
                                {job.is_impact &&
                                    <Box className={classes.containerJobType}>
                                        <SVGFactory name='earth' width="20" height="20" />
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box className={classes.containerBodyContentMobile}>
                            <Typography
                                variant='h3'
                                component='p'
                                className={classes.title}
                            >
                                {job.title || ''}
                            </Typography>
                        </Box>
                        <Box className={classes.containerBodyFooterMobile}>
                            <Box className={classes.containerBodySubFooterMobile}>
                                {job.job_tags && Array.isArray(job.job_tags) && <JobCardChips job={job} />}
                            </Box>
                            <Box className={classes.containerBodySubFooterMobile}>
                                <Box className={classes.containerLocation}>
                                    <SVGFactory
                                        skipSvgWrapper
                                        {...props}
                                        name='location'
                                    />
                                    <Typography
                                        component='p'
                                        className={classes.location}
                                    >
                                        {job.city || ''}
                                    </Typography>
                                </Box>
                                <FavoriteAction
                                    userType={props.userType}
                                    color='var(--black)'
                                    item={job}
                                    onItemCard
                                />
                            </Box>
                        </Box>
                    </Box>
                </Hidden>
            </Box>
        </JobCardWrapper>
    )
}

export default JobCardV2
