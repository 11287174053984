import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobCardWrapper: {
        cursor: 'pointer',
        width: '100%'
    },
    routeMapLinkStyle: {
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: 320,
        minWidth: 280,
        maxWidth: 320,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        borderRadius: 12,
        margin: '0 auto'
    },
    jobCardImage: {
        height: '45%',
        position: 'relative'
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '12px 12px 0 0',
        objectFit: 'cover'
    },
    company: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        display: 'flex',
        alignItems: 'flex-end',
        '& img': {
            width: 55,
            height: 55,
            objectFit: 'contain',
            borderRadius: 10,
            backgroundColor: 'white'
        }
    },
    companyInfo: {
        color: 'white',
        margin: '0 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    companyInfoName: {
        color: 'white',
        textTransform: 'uppercase'
    },
    companyInfoType: {
        color: 'white',
        fontFamily: "'GintoNormal-Light', sans-serif !important",
        fontSize: 12,
        fontWeight: 300
    },
    jobCardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 16
    },
    jobTitleAndFavourite: {
        display: 'flex',
        flexDirection: 'row'
    },
    jobTitle: {
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'clip',
        lineHeight: 1.4,
        maxHeight: 'calc(16px * 1.4 * 3)' // title max 3 lines -> calc(fontSize * lineHeight * number of lines)
    },
    jobFavourite: {
        display: 'flex',
        alignItems: 'baseline'
    },
    jobDetails: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'var(--medium-grey)',
        fontSize: '12px !important',
        whiteSpace: 'nowrap',
        overflowX: 'clip'
    },
    city: {
        fontFamily: "'GintoNormal-Light', sans-serif !important",
        fontSize: '12px !important',
        fontWeight: 300
    },
    bullet: {
        fontSize: 6,
        margin: '0 4px'
    },
    peopleWanted: {
        fontFamily: "'GintoNormal-Medium', sans-serif !important"
    },
    noMargin: {
        margin: 0
    },

    chipRoot: {
        height: 'fit-content',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 10,
        padding: '6px 10px'
    },

    // Rebranding JobCard V2
    containerCard: {
        border: `1px solid ${theme.palette.fg.silver}`,
        height: '123px',
        width: '100%',
        borderRadius: '20px',
        backgroundColor: theme.palette.bg['white'],
        display: 'flex',
        transition: 'all 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            borderColor: `1px solid ${theme.palette.fg.black}`
        },
        [theme.breakpoints.down('xs')]: {
            height: '206px',
        }
    },
    containerCover: {
        position: 'relative',
        width: '140px',
        minWidth: '140px',
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px'
    },
    containerLogoCompany: {
        border: '1.4px solid var(--background-silver)',
        position: 'absolute',
        right: '-25%',
        top: '12px',
        width: '63px',
        height: '63px',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--background-white)',
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        }
    },
    containerLogoCompanyMobile: {
        border: `1px solid ${theme.palette.fg.silver}`,
        width: '49px',
        height: '49px',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.bg.white,
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        }
    },
    containerBody: {
        padding: '12px 16px 18px 46px',
        display: 'flex',
        flex: 1
    },
    containerBodyMobile: {
        [theme.breakpoints.down('xs')]: {
            padding: '16px 13px',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            borderRadius: '20px',
            width: '100%'
        }
    },
    containerBodyHeaderMobile: {
        width: '100%',
        paddingBottom: '7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    containerBodyHeaderRightMobile: {
        display: 'flex',
        alignItems: 'center',
        gap: '17px'
    },
    containerBodyContentMobile: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    containerBodyFooterMobile: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '11px'
    },
    containerBodySubFooterMobile: {
        width: '100%',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'space-between'
    },

    containerBodyLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%'
    },
    containerBodyRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cardMediaV2: {
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    },
    companyMedia: {
        width: '36px',
        height: '36px',
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: {
            width: '27px',
            height: '27px'
        }
    },
    companyName: {
        fontSize: '10px',
        lineHeight: '13px'
    },
    companyNameMobile: {
        color: theme.palette.fg.black,
        fontSize: '13px',
        lineHeight: '16px',
        textTransform: 'uppercase'
    },
    containerTitles: {
        height: '63px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '4px'
    },
    title: {
        fontSize: '20px',
        lineHeight: '23px'
    },
    containerLocation: {
        display: 'flex',
        gap: '6px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            gap: '4px'
        }
    },
    location: {
        color: theme.palette.fg['black40Opacity'],
        fontSize: '12px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize'
    },
    containerTags: {
        display: 'flex',
        gap: '15px',
        overflowX: 'scroll',
        scrollbarWidth: 'none' /* Firefox */,
        '-ms-overflow-style': 'none' /* IE 10+ */,
        '&::-webkit-scrollbar': {
            height: 0, /* Chrome/Safari/Webkit */
            background: 'transparent'
        }
    },
    containerJobType: {
        height: '42px',
        width: '42px',
        borderRadius: '100%',
        backgroundColor: 'var(--background-green)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
