/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './paymentpage_style'
import { Box, Container, Typography } from '@material-ui/core'

import AboutPaymentHelmet from 'helmets/AboutPaymentHelmet'
import AnalyticsHelper from 'assets/AnalyticsHelper.js'
import CreditPackages from 'shared/containers/CreditPackages/CreditPackages'
import EmployerFindOutMoreBanner from 'shared/components/EmployerFindOutMoreBanner/EmployerFindOutMoreBanner.jsx'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import { usePipedrive, CHAT_EMPLOYER_GENERAL } from 'hooks/usePipedrive'
import HomeStats from '../HomeStudent/components/HomeStats/HomeStats'
import MultiFontText from 'shared/components/MultiFontText/MultiFontText'

const PaymentPage = (props) => {
    useEffect(() => {
        AnalyticsHelper.sendGTMEvent(
            'Pricing Plan List Viewed',
            AnalyticsHelper.constructPricingPagViewedeAnalyticsAttributes(props.user, props.company)
        )
    }, [])

    useEffect(() => {
        const { instantiateChat } = usePipedrive()
        instantiateChat(CHAT_EMPLOYER_GENERAL)
    }, [])

    const classes = useStyles()
    return (
        <PageWrapper user={props.user} disableFooterLists>
            <AboutPaymentHelmet />
            <Box className={classes.banner}>
                <Container maxWidth='lg' className={classes.bannerContainer}>
                    <Box className={classes.bannerText}>
                        <MultiFontText>
                            <FormattedMessage id="landing_payment_page.title_3">
                                {text =>
                                    <Typography variant='h6' component='h1' className={classes.pageTitle} dangerouslySetInnerHTML={{ __html: text }} />
                                }
                            </FormattedMessage>
                        </MultiFontText>
                        <Typography variant='body1' component='p' className={classes.pageSubTitle}>
                            <FormattedMessage id="landing_payment_page.subtitle_3" />
                        </Typography>
                        <Typography variant='body1' component='p' className={classes.pageSubTitle}>
                            <FormattedMessage id="landing_payment_page.subtitle_4" />
                        </Typography>
                    </Box>
                </Container>
			</Box>
            <Box className={classes.creditPackagesContainer}>
                <Container maxWidth='lg'>
                    <CreditPackages analyticsId='PaymentPage'/>
                </Container>
            </Box>
            <EmployerFindOutMoreBanner imageId='assets/payment_find_out_more' context="PaymentPage"/>
            <Container maxWidth='lg' className={classes.homeStatsContainer}>
                <HomeStats />
            </Container>
        </PageWrapper>
    )
}

export default PaymentPage
