import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        height: 'calc(100vh - 77px)', // viewport height minus navbar height
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            background: 'var(--red)',
            height: 'calc(100vh - 120px)', // ensure mobile browser bottom bar does not cover website content
        }
	}
}))
