import { grey } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

export const THEME = createTheme({
    typography: {
        fontFamily: "'GreedStandard-Regular', sans-serif",
        fontSize: '16px',
        color: 'var(--black)',
        a: {
            textDecoration: 'none'
        },
        h1: { // SHOWS TITLE
            fontFamily: "'GreedStandard-Bold', sans-serif",
            color: 'var(--black)',
            [breakpoints.down('md')]: {
                fontSize: '24px'
            },
            [breakpoints.up('md')]: {
                fontSize: '22px'
            }
        },
        h2: { // SECTIONS
            fontFamily: "'GreedStandard-Medium', sans-serif",
            color: 'var(--black)',
            [breakpoints.down('md')]: {
                fontSize: '20px'
            },
            [breakpoints.up('md')]: {
                fontSize: '18px'
            }
        },
        h3: { // CARDS TITLE
            fontFamily: "'GreedStandard-SemiBold', sans-serif",
            color: 'var(--black)',
            lineHeight: 1.4,
            [breakpoints.down('md')]: {
                fontSize: '16px'
            },
            [breakpoints.up('md')]: {
                fontSize: '16px'
            }
        },
        h4: { // SMALL TITLE / Field TITLE
            fontFamily: "'GreedStandard-Medium', sans-serif",
            color: 'var(--black)',
            [breakpoints.down('md')]: {
                fontSize: '14px !important'
            },
            [breakpoints.up('md')]: {
                fontSize: '14px !important'
            }
        },
        h5: { // SMALLEST TITLE
            fontFamily: "'GreedStandard-Medium', sans-serif",
            [breakpoints.down('md')]: {
                fontSize: '12px'
            },
            [breakpoints.up('md')]: {
                fontSize: '12px'
            }
        },
        h6: { // SLOGAN
            fontFamily: "'GreedStandard-Bold', sans-serif",
            [breakpoints.down('md')]: {
                fontSize: '16px'
            },
            [breakpoints.up('md')]: {
                fontSize: '16px'
            }
        },
        body1: { // REGULAR TEXT
            fontFamily: "'GreedStandard-Regular', sans-serif",
            fontSize: '16px',
            color: 'var(--black)'
            // margin: 'unset !important'
        },
        subtitle1: { // ADDITIONAL INFOS TEXT
            fontFamily: "'GreedStandard-Light', sans-serif",
            fontSize: '0.875rem',
            color: 'var(--foreground-grey-dark)',
            lineHeight: 1.5
        },
        body2: { // SMALL REGULAR TEXT
            fontFamily: 'GreedStandard-Regular, sans-serif',
            fontSize: '0.875rem'
            // margin: 'unset !important'
        },
        subtitle2: { // NOT USED YET
            fontFamily: "'GreedStandard-Light', sans-serif",
            fontSize: '0.875rem'
        }
        //  'caption'
        // | 'button'
        // | 'overline'
        // | 'srOnly'
    },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'testingOne' },
                    styles: {
                        fontFamily: "'GreedStandard-Bold', sans-serif",
                        fontSize: '30px'
                    }
                }
            ]
        }
    },
    // URL DOC
    // https://next.material-ui.com/customization/components/#adding-new-component-variants
    palette: {
        background: {
            default: 'white'
        },
        primary: {
            light: grey['700'],
            main: 'rgb(0,0,0)',
            dark: 'var(--black)',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#a000ff',
            contrastText: '#FFFFFF'
        },
        success: {
            main: '#76DA77'
        },
        error: {
            main: '#f44336',
            dark: '#d32f2f'
        },
        info: {
            main: '#343434'
        },

        // ############## V2 design ##############

        //  Background colors
        bg: {
            purple: 'var(--background-purple)',
            white: 'var(--background-white)',
            silver: 'var(--background-silver)',
            yellow: 'var(--background-yellow)',
            blue: 'var(--background-blue)',
            green: 'var(--background-green)',
            orange: 'var(--background-orange)',
            black: 'var(--background-black)',
            greyLight: 'var(--background-grey-light)',
            greyPale: 'var(--background-grey-pale)',
        },

        //  Foreground colors
        fg: {
            black: 'var(--foreground-black)',
            white: 'var(--foreground-white)',
            purple: 'var(--foreground-purple)',
            silver: 'var(--foreground-silver)',
            blue: 'var(--foreground-blue)',
            black40Opacity: 'var(--foreground-black-40-opacity)',
            black60Opacity: 'var(--foreground-black-60-opacity)',
            black90Opacity: 'var(--foreground-black-90-opacity)',
            greyDark: 'var(--foreground-grey-dark)',
            beigeLight: 'var(--foreground-beige-light)',
            greySlate: 'var(--foreground-grey-slate)',
        }
    },

    // MUI THEME
    // DOC : https://v3.material-ui.com/api/mui-theme-provider/
    overrides: {
        MuiSelect: {
            select: {
                '&:focus': {
                    background: 'inherit !important'
                }
            }
        },
        MuiContainer: {
            root: {
                [breakpoints.up('lg')]: {
                    paddingLeft: 4,
                    paddingRight: 4
                }
            }
        }
    }
})
