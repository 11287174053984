/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobShowBanner_style'
import { Hidden, Divider, Box } from '@material-ui/core'

import JobShowBannerDesktop from './components/JobShowBannerDesktop/JobShowBannerDesktop'
import JobShowBannerDesktopSticky from './components/JobShowBannerDesktopSticky/JobShowBannerDesktopSticky'
import JobShowBannerMobile from './components/JobShowBannerMobile/JobShowBannerMobile'
import JobShowBannerMobileSticky from './components/JobShowBannerMobileSticky/JobShowBannerMobileSticky'
import BoostCompanyJobModal from 'shared/modals/BoostCompanyJobModal/BoostCompanyJobModal'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'

const JobShowBanner = (props) => {
    const { job, user, unavailable } = props
    const classes = useStyles()
    const { ref, inView } = useInView({ rootMargin: '0px 0px 230px 0px', threshold: 1, })
    const [showBoostCompanyJobModal, setShowBoostCompanyJobModal] = useState(false)
    const [creditCount, setCreditCount] = useState(user?.number_of_credits)
    const [feedback, setFeedback] = useState({ open: false, severity: 'success', message: null })

    const toggleActiveJobStatus = () => {
        window.location.reload()
    }
    const closeFeedback = () => setFeedback({ ...feedback, open: false })
    const toggleBoost = () => setShowBoostCompanyJobModal(true)

    return (
        <>
            <BoostCompanyJobModal
                showModal={showBoostCompanyJobModal}
                setShowModal={setShowBoostCompanyJobModal}
                setFeedback={setFeedback}
                jobToBoost={job}
                creditCount={creditCount}
                setCreditCount={setCreditCount}
                toggleActiveJobStatus={toggleActiveJobStatus}
                user={user}
                company={job.company}
                filters={{}}
            />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
                autoHideDuration={null}
            />
            <Hidden mdUp>
                <Box className={classes.imageBox}>
                    <Image
                        cloudName="studentbe"
                        publicId={job.job_show_cloudinary_key}
                        alt={job.job_show_cloudinary_key}
                        className={classes.image}
                    >
                        <Transformation
                            flags="progressive"
                            fetchFormat="auto"
                            quality="auto:best"
                            width={MEDIUM_SIZE}
                            crop="fill"
                        />
                    </Image>
                </Box>
            </Hidden>
            <Box ref={ref} className={classes.viewMonitor} />
            <Box className={classes.jobShowBanner}>
                <Hidden smDown>
                    {inView
                        ? <JobShowBannerDesktop job={job} user={user} unavailable={unavailable} toggleBoost={toggleBoost} />
                        : <JobShowBannerDesktopSticky job={job} user={user} unavailable={unavailable} />
                    }
                </Hidden>
                <Hidden mdUp>
                    {inView
                        ? <JobShowBannerMobile job={job} user={user} unavailable={unavailable} toggleBoost={toggleBoost} />
                        : <JobShowBannerMobileSticky job={job} user={user} unavailable={unavailable} />
                    }
                </Hidden>
            </Box>
        </>
    )
}

export default JobShowBanner
