import React, { useState } from 'react'
import { FormControl, MenuItem, Select, useTheme } from '@material-ui/core'
import OpenIcon from '../OpenIcon/OpenIcon'
import { useStyles } from '../../ExperienceProfile_style'
import { FormattedMessage } from 'react-intl'

const ExperienceHighSchoolYearInput = (props) => {
    const { value, inputRef, error, handleChangeFormation, textFontClassName, formationKey, disableUnderline, placeholderTextClassName, placeholderTextId } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const theme = useTheme()

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            PaperProps: {
                style: {
                    maxHeight: 350,
                    boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
                    border: `2px solid ${theme.palette.fg['black']}`,
                    backgroundColor: theme.palette.bg['white'],
                    borderRadius: 16
                }
            }
        })
    }

    return (
        <FormControl className={classes.formControl} required onClick={() => setOpen(!open)}>
            <Select
                className={`${textFontClassName} ${error && classes.textFieldError}`}
                disableUnderline={disableUnderline}
                value={value}
                inputProps={{ MenuProps: selectPaperStyle() }}
                onChange={handleChangeFormation(formationKey, 'high_school_year')}
                open={open}
                IconComponent={() => <OpenIcon open={open}/>}
                displayEmpty
                inputRef={inputRef}
            >
                {value === null &&
                    <MenuItem className={classes.menuItem} value={null}>
                        <span className={placeholderTextClassName}>
                            <FormattedMessage id={placeholderTextId} />
                        </span>
                    </MenuItem>
                }
                {[...Array(7)].map((elem, index) => (
                    <MenuItem className={classes.menuItem} value={index + 1} key={`experience-menu-item-${index}`}>
                        {index + 1}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ExperienceHighSchoolYearInput
