import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CompanyInfoCard_style'
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Sanitizer from 'assets/Sanitizer'

const CompanyInfoCard = (props) => {
    const { job } = props
    const [accordionIsExpanded, setAccordionIsExpanded] = useState(false)
    const [companyDescription, setCompanyDescription] = useState({
        firstPart: null,
        secondPart: null
    })
    const classes = useStyles()
    const theme = useTheme()

    const splitDescription = () => {
        const description = job?.company_description
        const initialRenderedDescriptionMaxLength = 185

        if (!description) return { firstPart: null, secondPart: null }

        if (description.length <= initialRenderedDescriptionMaxLength) return { firstPart: description, secondPart: null }

        const splitIndex = description.lastIndexOf(' ', initialRenderedDescriptionMaxLength)
        return {
            firstPart: description.substring(0, splitIndex),
            secondPart: description.substring(splitIndex)
        }
    }

    useEffect(() => {
        setCompanyDescription(splitDescription())
    }, [])

    return (
        <Box className={classes.companyInfoCardContainer}>
            <Typography variant='h1' component='h2' className={classes.title}>
                <FormattedMessage id='job_show.company_info_card.title_prefix' /> {job?.company_name}
            </Typography>
            {companyDescription.firstPart && <Box className={classes.descriptionContainer}>
                <Typography component='p' className={classes.description}
                    dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(`${companyDescription.firstPart}${accordionIsExpanded ? companyDescription.secondPart : ''}`) }} />
                {companyDescription.secondPart && <Accordion onChange={() => setAccordionIsExpanded(!accordionIsExpanded)} defaultExpanded={false} elevation={0} className={classes.accordion}>
                    <AccordionSummary classes={{ root: classes.accordionSummary, content: classes.accordionSummaryContent, expandIcon: classes.accordionSummaryIconButton }} expandIcon={<IconFactory icon='more' color={theme.palette.fg['black']} />}>
                        <Typography variant='h2' className={classes.accordionSummaryTitle}>
                            <FormattedMessage id={accordionIsExpanded ? 'job_show.company_info_card.accordion_placeholder_secondary'  : 'job_show.company_info_card.accordion_placeholder' }/>
                        </Typography>
                    </AccordionSummary>
                </Accordion>}
            </Box>}
            <RouteMapLink
                showItemType='company'
                showItemUrl={job.company_url}
                className={classes.companyPageLinkContainer}
            >
                <Typography variant='h1' component='p' className={classes.companyPageLinkText}>
                    <FormattedMessage id='job_show.company_info_card.company_page_link_title' />
                </Typography>
                <Box className={classes.linkIconContainer}>
                    <IconFactory
                        icon='arrow-right'
                        color={theme.palette.fg['white']}
                        width='10px'
                        height='10px'
                    />
                </Box>
            </RouteMapLink>
        </Box>
    )
}

export default CompanyInfoCard
