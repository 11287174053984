import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    errorText: {
        fontSize: 12,
        color: 'var(--red)'
    },
    textField: {
        fontFamily: "'GreedStandard-Medium', sans-serif",
        padding: 12,
        backgroundColor: 'var(--background-white)',
        border: '1px solid var(--background-black)',
        borderRadius: 20,
        width: '100%',
        height: '100%',
        fontSize: 14,
        '&::placeholder': {
            color: 'var(--foreground-grey-slate)',
            opacity: 1
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 16, // prevents browser zoom on input
        }
    },
    shortField: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    maxWidth: {
        width: '100%'
    },
    formContainer: {
        width: '100%'
    },
    formField: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    selectionContainer: {
        display: 'flex',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    locationSubtitle: {
        color: 'var(--foreground-black-60-opacity)'
    },
    formLabelSelect: {
        fontSize: 20
    },
    formLabelText: {
        fontSize: 20,
    },
    addVideo: {
        flex: '1 1 0px',
    },
    addImage: {
        flex: '1 1 0px',
    },
    quillStyling: {
        '& .ql-toolbar': {
            borderRadius: '20px 20px 0 0',
            background: 'var(--background-silver)',
            fontFamily: "'GreedStandard-Regular', sans-serif"
        },
        '& .ql-container': {
            borderRadius: '0 0 20px 20px',
        },
        '& .ql-editor': {
            minHeight: 250
        }
    },
    dateInput: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    dateSelection: {
        background: 'var(--background-silver)',
        padding: "0 8px",
        borderRadius: 8,
        '& input': {
            background: 'var(--background-silver)'
        },
    },
    iosSwitch: {
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff !important',
            '& + .MuiSwitch-track': {
                backgroundColor: 'var(--background-purple) !important',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d !important',
            border: '6px solid #fff !important',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: 'var(--grey)',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
            duration: 500,
            }),
        }
    }
}))
