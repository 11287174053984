import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './homesearch_style'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

import { UserContext } from 'contexts/UserContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import Button from 'components/Button/Button.jsx'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMap from 'assets/RouteMap.js'
import SelectFilter from 'components/Searchbar/shared/SelectFilter/SelectFilter'
import UserAPI from 'api/UserAPI'

const HomeSearch = (props) => {
    const classes = useStyles()
    const { user, minimal } = props
    const userContext = useContext(UserContext)
    const itemTypeCollection = CollectionHandler.Get('itemTypeCollection')
    const cityCollection = CollectionHandler.Get('cityCollection')
    const [jobType, setJobType] = useState(itemTypeCollection[0].value)
    const [filters, setFilters] = useState({ location: [] })
    const [searchCities, setSearchCities] = useState(cityCollection)
    const [jobTypeOpen, setJobTypeOpen] = useState(false)
    const [locationFilterOpen, setLocationFilterOpen] = useState(false)

    const handleChangeJob = (event) => {
        setJobType(event.target.value)
    }

    const handleChangeLocation = (event) => {
        setFilters({ location: [event.target.value] })
        setLocationFilterOpen(true)
    }

    const renderIcon = () => {
        return (
            <Box className={classes.iconBox}>
                <IconFactory icon='down-solid' fontSize='12px' color='black' />
            </Box>
        )
    }

    const updateStatusConfig = () => {
        const newStatusConfig = {
            ...userContext.statusConfig,
            not_looking_any_job: false
        }
        if (jobType === 'student_job') newStatusConfig.actively_looking_student_job = true
        if (jobType === 'internship') newStatusConfig.actively_looking_internship = true
        if (jobType === 'first_job') newStatusConfig.actively_looking_first_job = true
        if (jobType === 'kot') newStatusConfig.actively_looking_kot = true

        userContext.setStatusConfig(newStatusConfig)

        return newStatusConfig
    }

    const updateProfile = () => {
        const newStatusConfig = updateStatusConfig()
        const profileData = {
            only_profile: true,
            ...newStatusConfig
        }
        UserAPI.UserEditMyProfile(profileData).then(response => {
            AnalyticsHelper.sendGTMEvent(
                'Student Status Updated',
                AnalyticsHelper.constructStudentStatusAnalyticsAttributes(user, userContext.statusConfig)
            )
            window.location.href = getTargetUrl()
        })
    }

    const getTargetUrl = () => {
        if (filters.location.length === 0) {
            return RouteMap.Index(jobType)
        } else if (filters.location.length === 1) {
            return RouteMap.Tag(jobType, 'cityCollection', filters.location[0].value_name)
        } else {
            let params = ''
            filters.location.forEach((city) => {
                params += `location=${city.value},${city.lat},${city.long}&`
            })
            if (params.endsWith('&')) { params = params.slice(0, -1) }

            return RouteMap.Index(jobType, params)
        }
    }

    return (
        <Box className={`${classes.homeSearchContainer} ${minimal && classes.searchContainerMinimal}`}>
            {minimal
                ? <Typography
                    variant='h6'
                    component='h2'
                    className={classes.title}
                >
                    <FormattedMessage id="landing_student.home_search.title" />
                </Typography>
                : <Typography className={classes.mainTitle}>
                    <FormattedMessage id='landing_student.home_search.main_title' />
                </Typography>
            }
            <Box className={`${classes.inputContainer} ${minimal && classes.inputContainerMinimal}`}>
                <Box className={classes.inputFieldsContainer}>
                    <FormControl className={classes.formField}>
                        <SelectFilter
                            className={classes.filter}
                            value={jobType}
                            name='type'
                            collection={itemTypeCollection}
                            onChange={handleChangeJob}
                            IconComponent={renderIcon}
                            open={jobTypeOpen}
                            onClick={() => setJobTypeOpen(!jobTypeOpen)}
                        />
                        {!minimal &&
                            <Typography variant='h3' className={classes.in}>
                                <FormattedMessage id='landing_student.home_search.at' />
                            </Typography>
                        }
                        <CityFilter
                            searchView
                            className={classes.filter}
                            handleInputSearchCity={handleChangeLocation}
                            searchCities={searchCities}
                            setSearchCities={setSearchCities}
                            setFilters={setFilters}
                            filters={filters}
                            open={locationFilterOpen}
                            setOpen={setLocationFilterOpen}
                            width={500}
                        />
                    </FormControl>
                </Box>
                <Box className={classes.buttonContainer}>
                    {userContext &&
                        <Button
                            variant='cta-primary'
                            onClick={updateProfile}
                            className={classes.button}
                        >
                            <FormattedMessage id='landing_student.home_search.valid_btn' />
                        </Button>
                    }
                    {!userContext &&
                        <Button
                            variant='cta-primary'
                            onClick={() => { window.location.href = getTargetUrl() }}
                            className={classes.button}
                        >
                            <FormattedMessage id='landing_student.home_search.valid_btn' />
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default HomeSearch
