import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    mainBox: {
        marginBottom: 30,
        marginTop: 44,
        [theme.breakpoints.down('md')]: {
            marginTop: 24
        }
    },
    title: {
        fontFamily: 'GreedWide-Bold',
        fontSize: 40,
        marginBottom: 15
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20
    },
    readingTime: {
        display: 'flex',
        alignItems: 'center',
        gap: 4
    },
    share: {
        display: 'flex',
        alignItems: 'center'
    },
    editIcon: {
        color: 'var(--foreground-silver)'
    },
    homeLine: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 40,
        marginBottom: 30
    },
    homeLineBlogHome: {
        cursor: 'pointer',
        color: 'var(--foreground-grey-dark)'
    },
    homeLineTitle: {
        fontFamily: "'GreedStandard-Medium', sans-serif"
    }
}))
