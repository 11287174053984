import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobShowBannerDesktop_style'
import { Box, Chip, Typography, Container } from '@material-ui/core'

import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ApplyButton from '../../../ApplyButton/ApplyButton'
import EmployerJobIndexHelper from 'pages/user/EmployerJobIndex/EmployerJobIndexHelper'
import Button from 'shared/components/Button/Button.jsx'

const JobShowBannerDesktop = (props) => {
    const { user, job, unavailable, toggleBoost } = props
    const classes = useStyles()
    const userHasAccessToBoost = EmployerJobIndexHelper.userHasAccessToBoost(job, user, job.current_user_is_job_owner)
    const isJobBoostable = EmployerJobIndexHelper.isJobBoostable(job)

    return (
        <Container className={classes.bannerContainer} maxWidth='lg' disableGutters>
            <Box className={classes.leftContainer}>
                <Box className={classes.companyLogoContainer}>
                    <RouteMapLink
                        showItemType='company'
                        showItemUrl={job.company_url}
                        className={classes.logoBox}
                    >
                        <Image
                            className={classes.logo}
                            cloudName="studentbe"
                            publicId={job.company_logo_cloudinary_key ? job.company_logo_cloudinary_key : 'default-company-logo-black'}
                            alt={`${job.company_logo_cloudinary_key} logo`}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" width="150" quality="auto:good" crop="scale" />
                        </Image>
                    </RouteMapLink>
                </Box>
                <Box className={classes.textContainer}>
                    <RouteMapLink
                        showItemType='company'
                        showItemUrl={job.company_url}
                    >
                        <Typography component='h2' className={classes.companyName}>
                            {job.company_name?.toUpperCase()}
                        </Typography>
                    </RouteMapLink>
                    <Typography component='h1' className={classes.jobTitle}>
                        {job.title}
                    </Typography>
                    <Box className={classes.tagsContainer}>
                        {job.company_size &&
                            <Chip
                                classes={{ root: classes.tag, label: classes.tagLabel }}
                                label={CollectionHandler.Translate('companySizeCollection', job.company_size)}
                            />
                        }
                        {job.sector &&
                            <Chip
                                classes={{ root: classes.tag, label: classes.tagLabel }}
                                label={CollectionHandler.Translate('sectorCollection', job.sector)}
                            />
                        }
                    </Box>
                </Box>
            </Box>
            {!unavailable && <Box className={classes.rightContainer}>
                <Box className={classes.iconButtonsContainer}>
                    <FavoriteAction
                        userType={user?.type}
                        type={job.type}
                        id={job.id}
                        item={job}
                        size='20px'
                        padding='0px'
                    />
                    <ShareAction
                        infos={{
                            title: job.title,
                            url: window.location.href,
                            type: job.type,
                            shareText: 'transl_internship.share_button'
                        }}
                        userType={user?.type}
                        size='20px'
                        padding='0px'
                    />
                </Box>
                <Box className={classes.buttonsContainer}>
                    <ApplyButton
                        containerClass={classes.applyButtonContainer}
                        job={job}
                        user={user}
                        unavailable={unavailable}
                    />
                    {userHasAccessToBoost &&
                        <Button
                            onClick={toggleBoost}
                            disabled={!isJobBoostable}
                        >{isJobBoostable ? <FormattedMessage id="company_job_card.buttons.boost" /> : <FormattedMessage id="company_job_card.buttons.boosted" />}</Button>}
                </Box>
            </Box>}
        </Container>
    )
}

export default JobShowBannerDesktop
