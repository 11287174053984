import React from 'react'

import JobCardV2 from 'cards/JobCard/JobCardV2'
import ArticleCardNew from 'cards/ArticleCardNew/ArticleCardNew'
import KotCard from 'cards/KotCard/KotCard'
import CompanyCard from 'cards/CompanyCard/CompanyCard'
import NavigationCard from 'cards/NavigationCard/NavigationCard'
import VideoCard from 'cards/VideoCard/VideoCard'
import CompanyCardAlt from '../CompanyCardAlt/CompanyCardAlt'

const CardFactory = (props) => {
    const createCard = (card) => {
        let createCard
        switch (props.type) {
        case 'article':
            createCard = article
            break
        case 'navigation':
            createCard = navigation
            break
        case 'kot':
            createCard = kot
            break
        case 'company':
            createCard = company
            break
        case 'video':
            createCard = video
            break
        case 'companyFilter':
            createCard = companyFilter
            break
        default:
            createCard = normal
            break
        }
        return createCard(card)
    }

    const normal = (job) => {
        return (
            <JobCardV2
                job={job}
                id={'jobcard_' + job.id}
                key={'jobcard_' + job.id}
                userType={props.userType}
                showJobType={props.showJobType}
            />
        )
    }

    const article = (article) => {
        return (
            <ArticleCardNew
                article={article}
                id={'articlecard_' + article.id}
                key={'articlecard_' + article.id}
                displayShow={props.displayShow}
                forwardRef={props.cardsRef}
                articleOwner={props.articleOwner}
                remove={props.remove}
            />
        )
    }

    const navigation = (card) => {
        console.log(card)
        return (
            <NavigationCard
                title={card.title ? card.title : 'Missing'}
                content={card.content ? card.content : 'Missing'}
                url={card.url ? card.url : '#'}
            />
        )
    }

    const kot = (kot) => {
        return (
            <KotCard
                kot={kot}
                key={'kotcard_' + kot.id}
                id= {'kotcard_' + kot.id}
                userType={props.userType}
                displayShow={props.displayShow}
                mapProviderProps={props.mapProviderProps}
                forwardRef={props.cardsRef}
            />
        )
    }

    const company = (company) => {
        return (
            <CompanyCard
                company={company}
                key={'companycard_' + company.id}
                id={'companycard_' + company.id}
            />
        )
    }

    const video = (video) => {
        return (
            <VideoCard
                videoId={video.youtube_video_id}
            />
        )
    }

    const companyFilter = (filter) => {
        return (
            <CompanyCardAlt
                filter={filter}
            />
        )
    }

    return (
        createCard(props.card, props.index)
    )
}

export default CardFactory
