import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyInfoCardContainer: {
        width: '100%',
        maxWidth: '341px',
        border: `1px solid ${theme.palette.fg.silver}`,
        borderRadius: '20px',
        padding: '32px 23px 32px'
    },

    title: {
        paddingBottom: '4px',
        color: theme.palette.fg.black,
        fontSize: '24px',
        lineHeight: '28px'
    },

    descriptionContainer: {
        paddingBottom: '22px'
    },

    description: {
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '18px',
        '& h1, h2, h3': {
            fontSize: 16
        }
    },

    accordion: {
        '&::before': {
            display: 'none'
        }
    },

    accordionSummary: {
        minHeight: 0,
        padding: '0px',
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '18px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: '8px',
        '&:hover $accordionSummaryContent h2': {
            transition: 'all 0.3s ease',
            color: theme.palette.fg.purple
        },
        '&:hover svg': {
            transition: 'all 0.3s ease',
            color: `${theme.palette.fg.purple} !important`
        }
    },

    accordionSummaryContent: {
        display: 'block',
        margin: '0px',
        flexGrow: 'unset'
    },

    accordionSummaryTitle: {
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '18px'
    },

    accordionSummaryIconButton: {
        margin: '0px',
        padding: '0px'
    },

    companyPageLinkContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '11px',
        '&:hover $companyPageLinkText': {
            transition: 'all 0.3s ease',
            color: theme.palette.fg.purple
        },
        '&:hover $linkIconContainer': {
            transition: 'all 0.3s ease',
            backgroundColor: theme.palette.bg.purple
        }
    },

    companyPageLinkText: {
        color: theme.palette.fg.black,
        fontSize: '18px',
        lineHeight: '28px'
    },

    linkIconContainer: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
        minHeight: '24px',
        borderRadius: '50px',ackgroundColor: theme.palette.bg.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))
