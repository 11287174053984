import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        listStyle: 'none',
        border: 'solid 1px var(--foreground-silver)',
        borderRadius: '20px',
        padding: '20px 24px',
        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: '100px'
        }
    },
    reactionBox: {
        marginTop: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    emoji: {
        width: 'fit-content',
        filter: 'grayscale(1)',
        cursor: 'pointer',
        display: 'flex',
        '&:last-child': {
            marginBottom: 0
        },
        '&:hover': {
            transform: 'scale(1.1)',
            filter: 'grayscale(0)'
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 10
        }
    },
    emojiImage: {
        width: 25
    },
    noneSelected: {
        filter: 'grayscale(0)'
    },
    selected: {
        transform: 'scale(1.1)',
        filter: 'grayscale(0)',
        fill: 'var(--background-purple)'
    },
    animated: {
        animation: '$naturalBounce 1000ms cubic-bezier(0.280, 0.840, 0.420, 1)'
    },
    '@keyframes naturalBounce': {
        '0%': { transform: 'scale(1,1) translateY(0)' },
        '10%': { transform: 'scale(1.1,.9) translateY(0)' },
        '30%': { transform: 'scale(.9,1.1) translateY(-20px)' },
        '50%': { transform: 'scale(1.05,.95) translateY(0)' },
        '57%': { transform: 'scale(1,1) translateY(-7px)' },
        '64%': { transform: 'scale(1,1) translateY(0)' },
        '100%': { transform: 'scale(1,1) translateY(0)' }
    }
}))
