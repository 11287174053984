import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CompanyCard_style'
import { Box, Chip, Typography, useTheme } from '@material-ui/core'

import useJobIcon from 'hooks/useJobIcon'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { MEDIUM_SMALL_SIZE, SMALLER_SIZE } from 'assets/CloudinaryURLHelper'
import CollectionHandler from 'assets/CollectionHandler.js'
import isNull from 'lodash.isnull'
import URLHandler from 'assets/URLHandler'
import { COMPANIES_WITH_TAGS, PUBLIC_COMPANY } from 'assets/ItemHelper'
import Sanitizer from 'assets/Sanitizer'

const CompanyCard = (props) => {
    const classes = useStyles()
    const { company } = props
    const theme = useTheme()

    const companyCity = company.city || null
    const i18nCompanySize = company.size && CollectionHandler.Translate('companySizeCollection', company.size)
    const i18nCompanySector = company.sector && CollectionHandler.Translate('sectorCollection', company.sector)

    const companyImageId = company?.banner_image?.cloudinary_key || company?.main_image?.cloudinary_key
    const companyCardYoutubeId = company.main_video_youtube_id
    const youtubeImageUrl = URLHandler.youtubeImageUrlBuilder(companyCardYoutubeId)
    const defaultImage = `placeholders/${company.sector || 'other_industries'}.png`
    const formattedCompanyDescription = Sanitizer.RemoveHTML(company.description_current_locale_or_default?.substring(0, 300))

    const constructCompanyTags = () => {
        let companyTags = []
        if (COMPANIES_WITH_TAGS.includes(company.size)) {
            companyTags = [companyCity, i18nCompanySize, i18nCompanySector]
        } else if (company.size === PUBLIC_COMPANY) {
            companyTags = [i18nCompanySize, i18nCompanySector]
        } else {
            companyTags = [i18nCompanySector]
        }
        return companyTags.filter(tag => !isNull(tag))
    }
    const companyTags = constructCompanyTags()

    return (
        <RouteMapLink showItemType='company' showItemUrl={company.url} className={classes.routeMapLinkStyle}>
            <Box className={classes.container} key={company.id}>
                <span className={classes.companyCardImage} role='img' aria-label={`banner image ${company.name}`}>
                    {!companyImageId && companyCardYoutubeId
                        ? <img
                            className={classes.cardMedia}
                            style={{
                                backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%), url(${youtubeImageUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: 'black'
                            }}
                        />
                        : <Image
                            cloudName="studentbe"
                            className={classes.cardMedia}
                            publicId={companyImageId || defaultImage}
                            alt={`banner image ${company.name}`}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" height={MEDIUM_SMALL_SIZE} quality="auto:good" crop="fill" />
                            <Transformation defaultImage={defaultImage.replace('/', ':')} />
                        </Image>
                    }
                    <Box className={classes.companyLogo}>
                        {company.logo_cloudinary_key &&
                            <Image cloudName="studentbe" publicId={company.logo_cloudinary_key} alt={`Company logo ${company.name}`}>
                                <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:good" crop="fill" gravity="center" />
                            </Image>
                        }
                    </Box>
                </span>
                <Box className={classes.companyInfoContainer}>
                    <Box className={classes.upContainer}>
                        <Typography variant='h3' component='p' className={classes.companyName}>
                            {company.name}
                        </Typography>
                        {companyTags &&
                            <Box className={classes.chips}>
                                {companyTags.map((companyTag, i) =>
                                    <Chip
                                        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                                        label={companyTag}
                                        name={companyTag}
                                        key={i}
                                    />
                                )}
                            </Box>
                        }
                        <Typography
                            variant='body2'
                            className={classes.companyDescription}
                            dangerouslySetInnerHTML={{ __html: formattedCompanyDescription }}
                        />
                    </Box>
                    <Box className={classes.downContainer}>
                        {company.people_wanted_by_job_type &&
                            <Box className={classes.peopleWantedContainer}>
                                {company.people_wanted_by_job_type.filter((peopleWanted) => peopleWanted.count > 0).map((peopleWanted, i) =>
                                    <Box className={classes.peopleWanted} key={peopleWanted.type}>
                                        <IconFactory icon={useJobIcon(peopleWanted.type)} color={theme.palette.fg['greySlate']} fontSize='14px' />
                                        <Box className={classes.peopleWantedText}>
                                            {peopleWanted.count}&nbsp;<FormattedMessage id={peopleWanted.count > 1 ? peopleWanted.type + 's' : peopleWanted.type} />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default CompanyCard
