import React from 'react'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import ExperienceProfile from './ExperienceProfile/ExperienceProfile'
import InfoProfile from './InfosProfile/InfoProfile'
import LanguageProfile from './LanguageProfile/LanguageProfile'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import Paper from 'components/Paper/Paper'
import Button from 'components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import UploadDocument from 'containers/UploadDocument/UploadDocument'
import ProfilePassport from 'pages/user/PersonalDashboard/components/ProfilePassport/ProfilePassport'
import { useStyles } from './StudentProfile_style'
import { FormattedMessage } from 'react-intl'

const StudentProfilePageView = (props) => {
    const {
        blankLanguage,
        closeFeedback,
        disabledSave,
        feedback,
        handleDateBlur,
        handleDateChange,
        handleSubmit,
        imageArray,
        loading,
        pictureId,
        user,
        profile,
        selectedDate,
        setExperienceIsUpdated,
        setImageArray,
        setStudent,
        setStudentFormation,
        setStudentLanguage,
        student,
        studentFormation,
        studentLanguage
    } = props

    const classes = useStyles()

    return (
        <Container maxWidth='lg'>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                closeFeedback={closeFeedback}
            />
            {loading
                ? <ProgressCircle/>
                : <Box mb={5}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="my-items-selection-container">
                                    <Box mb={3} display='flex' justifyContent='flex-end'>
                                        <Box component={Grid} container item xs={12} sm={6} alignItems='center' justify='flex-end' >
                                            <Button
                                                type='submit'
                                                variant='tertiary'
                                            >
                                                <Box className={classes.saveButton}>
                                                    <FormattedMessage id="landing_student_profile_page.save_change" />
                                                    <IconFactory icon='save' />
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Grid container spacing={2}>
                                            {/* START AVATAR + JOB SEARCH STATUS */}
                                            <Grid item xs={12}>
                                                <ProfilePassport
                                                    context='student_profile'
                                                    user={user}
                                                    profileId={profile.id}
                                                    pictureId={pictureId}
                                                >
                                                    <Box className={classes.paper}>
                                                        <InfoProfile
                                                            setStudent={setStudent}
                                                            student={student}
                                                            selectedDate={selectedDate}
                                                            handleDateChange={handleDateChange}
                                                            handleDateBlur={handleDateBlur}
                                                            disabledSave={disabledSave}
                                                        />
                                                    </Box>
                                                </ProfilePassport>
                                            </Grid>
                                            {/* START DOCUMENTS */}
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant='h2' component='div'>
                                                                <FormattedMessage id="landing_student_profile_page.my_documents" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant={'caption'} component='div'>
                                                                <FormattedMessage id="landing_student_profile_page.download_documents" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <UploadDocument
                                                                page='studentProfile'
                                                                profileId={profile.id}
                                                                imageArray={imageArray}
                                                                setImageArray={setImageArray}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            {/* END DOCUMENTS */ }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <ExperienceProfile
                                                    disabledSave={disabledSave}
                                                    setStudentFormation={setStudentFormation}
                                                    studentFormation={studentFormation}
                                                    setExperienceIsUpdated={setExperienceIsUpdated}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LanguageProfile
                                                    blankLanguage={blankLanguage}
                                                    disabledSave={disabledSave}
                                                    setStudent={setStudent}
                                                    setStudentLanguage={setStudentLanguage}
                                                    student={student}
                                                    studentLanguage={studentLanguage}
                                                />
                                            </Grid>
                                            <Box component={Grid} container item xs={12} alignItems='center' justify='flex-end' >
                                                <Button
                                                    type='submit'
                                                    variant='tertiary'
                                                >
                                                    <Box className={classes.saveButton}>
                                                        <FormattedMessage id="landing_student_profile_page.save_change" />
                                                        <IconFactory icon='save' />
                                                    </Box>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            }
        </Container>
    )
}

export default StudentProfilePageView
