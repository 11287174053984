/* eslint-disable react/prop-types */

import React from 'react'

import KotCard from 'cards/KotCard/KotCard'
import ArticleListCard from 'cards/ArticleListCard/ArticleListCard'
import ArticleCardNew from 'cards/ArticleCardNew/ArticleCardNew'
import AlertListCard from 'cards/AlertListCard/AlertListCard'
import ActionCardDecorator from 'cards/ActionCardDecorator/ActionCardDecorator'
import CandidacyCard from 'cards/CandidacyCard/CandidacyCard'
import KotListCardAlt from 'cards/KotListCardAlt/KotListCardAlt'
import StudentHeadhuntCard from 'cards/StudentHeadhuntCard/StudentHeadhuntCard'
import JobResultsCard from 'cards/JobResultsCard/JobResultsCard'
import CompanyCard from 'cards/CompanyCard/CompanyCard'
import AdBanner from 'components/AdBanner/AdBanner'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './listContainer_style'
import JobCardV2 from 'cards/JobCard/JobCardV2'

class ListContainer extends React.Component {
    /**
     * @param props - Comes from your rails view.
     */
    constructor (props) {
        super(props)
        this.normal = this.normal.bind(this)
        this.kot = this.kot.bind(this)
        this.kotListAlt = this.kotListAlt.bind(this)
        this.article = this.article.bind(this)
        this.articleCard = this.articleCard.bind(this)
        this.alert = this.alert.bind(this)
        this.acitionCard = this.acitionCard.bind(this)
        this.candidacy = this.candidacy.bind(this)
        this.studentHeadHunt = this.studentHeadHunt.bind(this)
        this.jobResults = this.jobResults.bind(this)
        this.setCreateListCard = this.setCreateListCard.bind(this)
        this.company = this.company.bind(this)
        this.noResultsTextId = 'list_container.no_ads_publish'
        this.state = {
            createListCard: this.setCreateListCard()
        }
        this.listCardsRef = []
    }

    normal(job) {
        return (
            <Grid item className='gridItemJobCard' style={{ width: '100%' }}>
                <JobCardV2
                    job={job}
                    id={'listcard_' + job.id}
                    key={'listcard_' + job.id}
                    userType={this.props.userType}
                />
            </Grid>
        )
    }

    kot (kot, index) {
        return <KotCard
            kot={kot}
            key={'kotlistcard_' + kot.id}
            id= {'kotlistcard_' + kot.id}
            forwardRef={this.listCardsRef[index]}
            popupCall={this.props.popupCall}
            mapProviderProps={this.props.mapProviderProps}
            userType={this.props.userType}
        />
    }

    kotListAlt (kot, index) {
        return <KotListCardAlt
            kot = {kot}
            key={'kot-' + kot.id}
            onCheck={this.props.onCheck}
            ref={this.listCardsRef[index]}
            remove={this.props.remove}
            popupCall={this.props.popupCall}
            duplicate={this.props.duplicate}
        />
    }

    article (article, index) {
        return <ArticleListCard
            article={article}
            id={'articlearticle_' + article.id}
            key={'articlearticle_' + article.id}
            forwardRef={this.listCardsRef[index]}
        />
    }

    articleCard (article, index) {
        return <ArticleCardNew
            article={article}
            id={'articlearticle_' + article.id}
            key={'articlearticle_' + article.id}
            forwardRef={this.listCardsRef[index]}
        />
    }

    alert (alert, index) {
        return <AlertListCard
            alert={alert}
            key={'alert-' + alert.id}
            remove={this.props.remove}
            onCheck={this.props.onCheck}
            ref={this.listCardsRef[index]}
        />
    }

    acitionCard (item, index) {
        return <ActionCardDecorator
            item={item}
            type={this.props.type}
            key={'action-' + item[this.props.idKey]}
            onCheck={this.props.onCheck}
            ref={this.listCardsRef[index]}
            idKey={this.props.idKey}
            user={this.props.user}
        />
    }

    candidacy (candidacy, index) {
        return <CandidacyCard
            candidacy={candidacy}
            key={'candidacy-' + candidacy[this.props.idKey]}
            remove={this.props.remove}
            onCheck={this.props.onCheck}
            ref={this.listCardsRef[index]}
        />
    }

    studentHeadHunt (studentProfile, index) {
        return <StudentHeadhuntCard
            key={'StudentHeadhuntCard-' + index}
            studentProfile={studentProfile}
            ref={this.listCardsRef[index]}
            onCheck={this.props.onCheck}
            idKey={this.props.idKey}
        />
    }

    jobResults (studentProfile, index) {
        return <JobResultsCard
            key={'JobResultsCard-' + studentProfile.id}
            studentProfile={studentProfile}
            idKey={this.props.idKey}
            ref={this.listCardsRef[index]}
            onCheck={this.props.onCheck}
            deleteCard={this.props.deleteCard}
            jobType={this.props.jobType}
        />
    }

    company (company, index) {
        return <CompanyCard
            company={company}
            key={'companycard_' + company.id}
            id={'companycard_' + company.id}
        />
    }

    setCreateListCard () {
        let createListCard = this.normal

        switch (this.props.type) {
        case 'kot':
            this.noResultsTextId = 'list_container.no_kots'
            createListCard = this.kot
            break
        case 'kot_alt':
            this.noResultsTextId = 'list_container.no_kots_owned'
            createListCard = this.kotListAlt
            break
        case 'article':
            this.noResultsTextId = 'list_container.no_articles'
            createListCard = this.article
            break
        case 'article_card':
            this.noResultsTextId = 'list_container.no_articles'
            createListCard = this.articleCard
            break
        case 'alert':
            this.noResultsTextId = 'list_container.no_alerts'
            createListCard = this.alert
            break
        case 'favourite':
            this.noResultsTextId = 'list_container.no_favourites_publish'
            createListCard = this.acitionCard
            break
        case 'candidacy':
            this.noResultsTextId = 'list_container.no_applied'
            createListCard = this.candidacy
            break
        case 'student_headhunt':
            this.noResultsTextId = 'list_container.no_headhunts'
            createListCard = this.studentHeadHunt
            break
        case 'job_results':
            if (this.props.noResult) {
                this.noResultsTextId = 'list_container.no_job_filter'
            } else {
                this.noResultsTextId = 'list_container.no_job_results_publish'
            }
            createListCard = this.jobResults
            break
        case 'company':
            this.noResultsTextId = 'list_container.no_companies'
            createListCard = this.company
            break
        default:
            break
        }

        return createListCard
    }

    adPlace () {
        switch (this.props.type) {
        case 'first_job':
            return 'Firstjobs_Index_Mobile_'
        case 'internship':
            return 'Internships_Index_Mobile_'
        case 'student_job':
            return 'Studentjobs_Index_Mobile_'
        case 'kot':
            return 'Kots_Index_Mobile_'
        case 'article':
            return 'Blog_Index_Mobile_'
        default:
            break
        }
    }

    listAds (index) {
        if (this.props.index === 0) {
            if (index === 1) {
                return <AdBanner key={`ad-banner-${index}`} ads={this.props.ads} place={this.adPlace() + '2'} device='mobile' width={320}/>
            } else if (index === 5) {
                return <AdBanner key={`ad-banner-${index}`} ads={this.props.ads} place={this.adPlace() + '3'} device='mobile' width={320}/>
            }
        } else if (this.props.index === 1) {
            if (index === 0) {
                return <AdBanner key={`ad-banner-${index}`} ads={this.props.ads} place={this.adPlace() + '4'} device='mobile' width={320}/>
            }
        }
    }

    render () {
        if (this.listCardsRef.length !== this.props.listCards.length) {
            for (let i = 0; i < this.props.listCards.length; i++) {
                this.listCardsRef.push(React.createRef())
            }
        }
        const cards = []
        for (var i = 0; i < this.props.listCards.length; i++) {
            const listCard = this.props.listCards[i]
            cards.push(
                <Grid
                    item
                    xs={12}
                    sm={['alert', 'candidacy', 'company-job', 'job_results', 'student_headhunt', 'favourite'].includes(this.props.type) ? 12 : 6}
                    md={['alert', 'candidacy', 'company-job', 'job_results', 'student_headhunt', 'favourite'].includes(this.props.type) ? 12 : 4}
                    lg={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    {this.state.createListCard(listCard, i)}
                </Grid>
            )
            this.props.showAds && cards.push(this.listAds(i))
        }
        return (
            <div className="listcard-container" id={'ListCard-' + this.props.id}>
                {
                    cards.length === 0
                        ? <Box my={8}>
                            <Typography align='center'>
                                <FormattedMessage id={this.noResultsTextId} />
                            </Typography>
                        </Box>
                        : <Grid container spacing={2}>
                            {cards}
                        </Grid>
                }
            </div>
        )
    }
}

ListContainer.defaultProps = {
    ad: true
}

export default withStyles(useStyles)(ListContainer)
